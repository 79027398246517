import { useDispatch, useSelector } from "react-redux";
import NAHeader from "../HomePage/Header/NAHeader";
import styles from "./PCCPatientDetails.module.css";
import { getIsCalOngoing, getUserProfile, getSelectedProjectIdAndLocationId } from "../../../Reducer/selectors/DashboardSelector";
import HubIcon from '@mui/icons-material/Hub';
import CloseIcon from '@mui/icons-material/Close';
import { getSelectedMlIdSelector, getSelectedPCCPatientSelector } from "../../../Reducer/selectors/DashboardSelector";
import {
    getSubjectHasMl, setShowMEDrawer, setShowQMDrawer, getMouthLabLogs, setSelectedPageName, setFloorEdit, getPCCUserProfile,
    setSelectedMlId, updateFacilityInfo, setPrevMeasurement, setSelectedSubjectId, getPatientVitalsList, setShowSaveCancelForFacility,
    setShowFooterButtons,
    setLatestMeasurement
} from "../../../Actions";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import Paper from "@mui/material/Paper";
import { useEffect, useState, useRef } from "react";
import { getPCCUserProfileSelector, getUserHasML, getFloorEdit, getShowSaveCancelForFacility, getShowFooterButtons } from "../../../Reducer/selectors/NursingDashboardSelector";
import ProfileIcon from "../../../Assets/icons/default-frame.png";
import { ReactComponent as MouthlabIcon } from "../../../Assets/icons/ML-Icon-Aidar-Bridge.svg";
import { Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Select from 'react-dropdown-select';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import QMPannel from "./QuickMeasurementPannel/QMPannel";
import MEPannel from "./ManualEntryPannel/MEPannel";
import BpCalibrationND from "../PatientRegistration/RegistrationForms/Calibration/BpCalibrationND";
import PostAssignMouthLabToSubject from "../../API/PostAssignMouthLabToSubject/PostAssignMouthLabToSubject";
import PostUnassignMouthLabFromSubject from "../../API/PostUnassignMouthLabFromSubject/PostUnassignMouthLabFromSubject";
import { getPatientVitalsListSelector } from "../../../Reducer/selectors/PatientSelector";
import CalibrationMeasurementPannel from "./CalibrationMeasurementPannel/CalibrationMeasurementPannel";
import Modal from "@material-ui/core/Modal";
import Backdrop from '@mui/material/Backdrop';
import { getpatientProfilePageLoaderAB } from "../../../Reducer/selectors/UtilSelector";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setPatientProfileLoaderAB, setShowLoader } from "../../../Actions/UtilActions";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import MLImg from '../../../Assets/Images/Aidar-Hand-Device-Sketch-01.png';
import { ReactComponent as WarningIcon } from "../../../Assets/Images/Alert-Icon.svg";
import WarningImg from '../../../Assets/Images/Warning.png';
import { useNavigate } from "react-router-dom";
import PatientNotes from "../../../Components/HomepageComponents/PatientDetails/PatientNotes/PatientNotes";

export default function PCCPatientDetails() {
    const profile = useSelector(getUserProfile);
    const selectedML = useSelector(getSelectedMlIdSelector);
    const dispatch = useDispatch();
    const [showModalUA, setShowModalUA] = useState(false);
    const selectedPCCPatient = useSelector(getSelectedPCCPatientSelector);
    const pccUserProfile = useSelector(getPCCUserProfileSelector);
    // console.log('pccUserProfile: ', pccUserProfile);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedTabProfile, setSelectedTabProfile] = useState(0);
    const ref = useRef(null);
    const ref2 = useRef(null);
    const refFacility = useRef();
    const [isWarningShown, setIsWarningShown] = useState(false);
    const [formState, setFormState] = useState(true);
    const [response, setResponse] = useState({
        statusCode: 0,
        message: "",
    });
    const projLoc = useSelector(getSelectedProjectIdAndLocationId);
    const selectedProjectId = projLoc.selectedProjectId;
    const selectedLocationId = projLoc.selectedLocationId;
    const currMeasurement = useSelector(getPatientVitalsListSelector);
    const [showDialog, setShowDialog] = useState(false);
    const isCalibrationOngoing = useSelector(getIsCalOngoing);
    const showFooterButtons = useSelector(getShowFooterButtons);
    const getUserHasMLPayload = useSelector(getUserHasML);
    const isCurrUserPaired = getUserHasMLPayload.hasDevice;
    const currUserDeviceId = getUserHasMLPayload.deviceId;
    // console.log('isCurrUserPaired: ', isCurrUserPaired);
    const [showDialogforUserPaired, setShowDialogforUserPaired] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showUALoader, setShowUALoader] = useState(false);
    const [uaMsg, setUaMsg] = useState('');
    const [floor, setFloor] = useState(pccUserProfile.floorId);
    const [barcode, setBarcode] = useState(pccUserProfile.barcode);
    const [unit, setUnit] = useState(pccUserProfile.unitId);
    // console.log('unit: ', unit);
    const [bed, setBed] = useState(pccUserProfile.bedDesc);
    const [room, setRoom] = useState(pccUserProfile.roomDesc);
    const showSaveCancelForFacility = useSelector(getShowSaveCancelForFacility);

    const isFloorEditable = useSelector(getFloorEdit);

    const patientProfilePageLoaderAB = useSelector(getpatientProfilePageLoaderAB);

    const isManualEntry = selectedML === '--';

    const [saveFacilityDisabled, setSaveFacilityDisabled] = useState(false);


    useEffect(() => {
        dispatch(setSelectedSubjectId(pccUserProfile.subjectId));
        dispatch(setPrevMeasurement(currMeasurement));
        console.log('main profile ',pccUserProfile);
    }, [pccUserProfile]);

    useEffect(() => {
        if (floor === '' || unit === '' || bed === '' || room === '') {
            setSaveFacilityDisabled(true);
        } else {
            setSaveFacilityDisabled(false);
        }
    }, [floor, unit, room, bed]);

    const optionsUnit = [
        { value: 'North', label: 'North' },
        { value: 'South', label: 'South' },
        { value: 'East', label: 'East' },
        { value: 'West', label: 'West' },
    ];

    useEffect(() => {
        const payload = {
            subjectId: pccUserProfile.subjectId
        }
        dispatch(getSubjectHasMl(payload));
    }, [pccUserProfile.subjectId]);

    useEffect(() => {
        setFloor(pccUserProfile.floorId);
        setBed(pccUserProfile.bedDesc);
        setRoom(pccUserProfile.roomDesc);
    }, [pccUserProfile]);

    const handlePairAndMeasure = () => {

        const payload = {
            subjectId: pccUserProfile.subjectId
        }
        dispatch(getSubjectHasMl(payload));

        if (isCurrUserPaired) {
            setShowDialogforUserPaired(true);
        } else {
            dispatch(getPatientVitalsList(pccUserProfile.guid));
            dispatch(setPrevMeasurement(currMeasurement));
            dispatch(setLatestMeasurement([]));
            let values = {
                deviceId: selectedML,
                subjectId: pccUserProfile.subjectId,
            }

            PostAssignMouthLabToSubject(values).then((res) => {
                // console.log("Device assignment values: ", values);
                // console.log("Device assignment response: ", res);
                setResponse({
                    statusCode: res.response ? res.response.status : res.error.response.status,
                    message: res.response ? res.response.data : res.error.response.data,
                });
            });

            ref.current.handleQuickDrawerOpen();
        }
    };

    const handleManualEntry = () => {
        ref2.current.handleQuickDrawerOpen();
    }

    const closeMenuUA = () => {
        setShowModalUA(false);
        changeState();
    };

    const changeState = () => {
        setFormState(!formState);
    };

    const unpairML = () => {
        setShowDialog(true);
        // PostUnassignMouthLabFromSubject({ deviceId: selectedML }).then((res) => {
        // });
        // navigate(`/deviceAssignments`);
    }

    function closeDialogMenu(event, reason) {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setShowDialog(false);
        }
    };

    function closeDialogDialogforUserPaired(event, reason) {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setShowDialogforUserPaired(false);
        }
    };

    useEffect(() => {
        dispatch(setSelectedPageName('profile'));
    }, []);

    useEffect(() => {
        return () => {
            window.addEventListener("beforeunload", function (e) {

                PostUnassignMouthLabFromSubject({ deviceId: selectedML }).then((res) => {
                    setResponse({
                        statusCode: res.response ? res.response.status : res.error.response.status,
                        message: res.response ? res.response.data : res.error.response.data,
                    });
                });
                dispatch(setShowQMDrawer(false));
                dispatch(setShowMEDrawer(false));
            });
        }
    }, []);

    const AntTab = withStyles((theme) => ({
        root: {
            textTransform: "none",
            paddingTop: `10px`,
            color: "#4E253A",
            background: `#ffffff`,
            fontSize: `16px`,
            fontFamily: ["GTWalsheimProRegular"].join(","),
            fontWeight: theme.typography.fontWeightBold,
            "&:last-child": {
                borderRight: `none`,
            },
            "&:hover": {
                color: "#4E253A",
                //opacity: 1,
            },
            "&:focus": {
                color: "#4E253A",
            },
            //selected tab styles
            "&$selected": {
                color: "#fff",
                backgroundColor: "#87668B",
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        selected: {},
    }))((props) => <Tab disableRipple {...props} />);

    const AntTabs = withStyles({
        root: {
            boxShadow: "0px 1px 5px 0px #cccaca",
            alignItems: "right",
            marginTop: `20px`,
            borderTopRightRadius: `10px`,
            borderTopLeftRadius: `10px`,
            backgroundColor: `#FFFFF`,
            width: "100%",
        },
        indicator: {
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "transparent",
            "& > span": {
                maxWidth: "100%",
                width: "100%",
                backgroundColor: "#87668B",
            },
        },
    })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
        if (newValue !== 2) {
            dispatch(setShowFooterButtons(true));
        }
    };

    const handleChangeTabProfile = (event, newValue) => {
        setFloorStates();
        setSelectedTabProfile(newValue);
    };
    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const unassignTheMl = async () => {
        setIsProcessing(true);
        setShowUALoader(true);
        await sleep(1000);
        PostUnassignMouthLabFromSubject({ deviceId: currUserDeviceId }).then((res) => {
        });
        await sleep(1000);
        setUaMsg('The device was successfully unassigned!');
        setShowUALoader(false);
        await sleep(2000);
        dispatch(getMouthLabLogs({ projectId: profile.projects[0].id, locationId: profile.locations[0].id }));
        setIsProcessing(false);
        setShowDialogforUserPaired(false);

        dispatch(getPatientVitalsList(pccUserProfile.guid));
        dispatch(setPrevMeasurement(currMeasurement));
        let values = {
            deviceId: selectedML,
            subjectId: pccUserProfile.subjectId,
        }

        PostAssignMouthLabToSubject(values).then((res) => {
            // console.log("Device assignment values: ", values);
            // console.log("Device assignment response: ", res);
            setResponse({
                statusCode: res.response ? res.response.status : res.error.response.status,
                message: res.response ? res.response.data : res.error.response.data,
            });
        });

        ref.current.handleQuickDrawerOpen();
    };

    const AntTabs1 = withStyles({
        root: {
            boxShadow: "0px 1px 5px 0px #cccaca",
            alignItems: "right",
            borderBottomRightRadius: `0px`,
            borderBottomLeftRadius: `0px`,
            backgroundColor: `#E3E3E4`,
            marginBottom: `2px`,
        },
        indicator: {
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: "transparent",
            "& > span": {
                maxWidth: "99%",
                width: "100%",
                backgroundColor: "#E9C9ED",
            },
        },
    })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

    const AntTab1 = withStyles((theme) => ({
        root: {
            textTransform: "none",
            paddingTop: `10px`,
            color: "#7C596A",
            background: `#feebf5`,
            borderRight: "1px solid #cccaca",
            fontSize: `15px`,
            fontFamily: ["GTWalsheimProRegular"].join(","),
            fontWeight: theme.typography.fontWeightBold,
            boxShadow: "0px 1px 5px 0px #cccaca",
            borderTopRightRadius: `0px`,
            borderTopLeftRadius: `0px`,
            borderBottomLeftRadius: `0px`,
            borderBottomRightRadius: `0px`,
            "&:last-child": {
                borderRight: `none`,
            },
            "&:hover": {
                color: "#4E253A",
                //opacity: 1,
            },
            "&$selected": {
                color: "#7C596A",
                fontWeight: theme.typography.fontWeightBold,
                backgroundColor: "#E9C9ED",
            },
            "&:focus": {
                color: "#fff",
                fontWeight: theme.typography.fontWeightBold,
                // backgroundColor: "#292F4C",
                // opacity: 1,
            },
        },
        selected: {},
    }))((props) => <Tab disableRipple {...props} />);

    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const navigate = useNavigate();

    const handleSubmit = () => {
        PostUnassignMouthLabFromSubject({ deviceId: selectedML }).then((res) => {
        });
        //console.log("Unselecting ML after unassigning...");
        dispatch(setSelectedMlId(""));
        navigate(`/deviceAssignments`);
    };

    const checkAndGetOtherComorbidities = () => {
        if (pccUserProfile.diagnoses && pccUserProfile.diagnoses[1] && pccUserProfile.diagnoses[1].type === 'Other Comorbidities') {
            return pccUserProfile.diagnoses[1].icdDesc;
        } else if (pccUserProfile.diagnoses && pccUserProfile.diagnoses[2] && pccUserProfile.diagnoses[2].type === 'Other Comorbidities') {
            return pccUserProfile.diagnoses[2].icdDesc;
        } else if (pccUserProfile.diagnoses && pccUserProfile.diagnoses[3] && pccUserProfile.diagnoses[3].type === 'Other Comorbidities') {
            return pccUserProfile.diagnoses[3].icdDesc;
        }
    }

    const handleFocus = (event) => event.target.select();

    const setFloorStates = () => {
        //console.log('setFloorStates()');
        setFloor(pccUserProfile.floorId ? pccUserProfile.floorId : '');
        setUnit(pccUserProfile.unitId ? pccUserProfile.unitId : '');
        setRoom(pccUserProfile.roomDesc ? pccUserProfile.roomDesc : '');
        setBed(pccUserProfile.bedDesc ? pccUserProfile.bedDesc : '');
    }

    const hideCancelSaveButtons = () => {
        setFloorStates();
        dispatch(setShowSaveCancelForFacility(false));
        dispatch(setFloorEdit(false));
    }

    const editFacility = (event) => {
        event.preventDefault();
        dispatch(setShowSaveCancelForFacility(true));
        dispatch(setFloorEdit(true));
    };

    const updateFacility = async () => {
        if (saveFacilityDisabled) {
            return;
        } else {
            let payload = {
                subjectId: selectedPCCPatient.patientId,
                floor: floor,
                unit: unit,
                room: room,
                bed: bed,
                barcode: barcode,
            };
            //console.log("updateFacility payload: ", payload);

            dispatch(updateFacilityInfo(payload));
            dispatch(setPatientProfileLoaderAB(true));
            const payload2 = { 'patientId': selectedPCCPatient.patientId, 'projectId': selectedProjectId, 'locationId': selectedLocationId };
            await sleep(3000);
            dispatch(getPCCUserProfile(payload2));
            dispatch(setShowSaveCancelForFacility(false));
            dispatch(setFloorEdit(false));
        }
    }

    useEffect(() => {
        //console.log('useEffect()');
        setFloorStates();
    }, []);

    useEffect(() => {
        dispatch(setPatientProfileLoaderAB(true));
        const payload = { 'patientId': selectedPCCPatient.patientId, 'projectId': selectedProjectId, 'locationId': selectedLocationId };
        dispatch(getPCCUserProfile(payload));
        setFloorStates();
    }, [selectedPCCPatient]);

    const dropdownStyles = {
        option: (provided, state) => ({
            ...provided,
            height: "3vh",
            backgroundColor: state.isSelected ? 'lightblue' : 'white', // Change background color for selected options
        }),
    };

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label && state.values[0].label.length > 25) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 25)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    const calculateAge = (birthDateString) => {
        const birthDate = new Date(birthDateString);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
    
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      };

    if (patientProfilePageLoaderAB) {
        return (<div className={styles.circularProgress}><CircularProgress color="#4E253A" thickness={5.0} /></div>);
    } else {
        return (
            <div className={styles.accContainer}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                    open={showDialog || showDialogforUserPaired}
                ></Backdrop>
                <main></main>
                <NAHeader userEmail={profile.email} />
                <div className={styles.selectedMLDiv}>
                    <div className={styles.MLIdDiv}>
                        <div className={styles.hubIconDiv}>
                            <HubIcon style={{ height: '16px', width: '16px', color: "#ffffff" }}></HubIcon>
                        </div>
                        ML {selectedML}
                    </div>
                    <div className={styles.unpairButton} onClick={unpairML}>
                        Change
                        <div className={styles.hubIconDiv}>
                            <CloseIcon style={{ height: '16px', width: '16px', color: "#ffffff" }}></CloseIcon>
                        </div>
                    </div>
                </div>
                <Paper className={styles.paperDiv} varient="outlined" elevation={4}>
                    <div className={styles.profileSummary}>
                        <div className={styles.pfpDiv}>
                            <div className={styles.imgDiv}>
                                <img src={ProfileIcon} height={70} width={70}></img>
                            </div>
                            <div className={styles.nameAndIdDiv}>
                                <div className={styles.patientName}> {selectedPCCPatient.fullName} </div>
                            </div>
                        </div>
                        <div className={styles.otherDetails}>
                            <div className={styles.otherDetails1}>
                                <div className={styles.infoDiv1}><div className={styles.infoLabel}> Age  </div> <div className={styles.infoContent}> {pccUserProfile.birthDate ? calculateAge(pccUserProfile.birthDate) : ''} </div></div>
                                <div className={styles.infoDiv}><div className={styles.infoLabel}> Room/Bed  </div> <div className={styles.infoContent}> {pccUserProfile.roomDesc + "/" + pccUserProfile.bedDesc} </div></div>
                                <div className={styles.infoDiv}><div className={styles.infoLabel}> MRN ID  </div> <div className={styles.infoContent}> {pccUserProfile.patientId ? pccUserProfile.patientId : ''} </div></div>
                            </div>
                            <Divider style={{ backgroundColor: "#F06868" }}></Divider>
                            <div className={styles.otherDetails2}>
                                <div className={styles.infoDiv1}><div className={styles.infoLabel}> Barcode  </div> <div className={styles.infoContent}> {pccUserProfile.barcode ? pccUserProfile.barcode : ''} </div></div>
                                <div className={styles.infoDiv}><div className={styles.infoLabel}> Floor  </div> <div className={styles.infoContent}> {pccUserProfile.floorId} </div></div>
                                <div className={styles.infoDiv}><div className={styles.infoLabel}> Sex at birth </div> <div className={styles.infoContent}> {pccUserProfile.gender} </div></div>
                            </div>
                            <div className={styles.otherDetails2}>
                            </div>
                        </div>
                    </div>

                    <AntTabs value={selectedTab} onChange={handleChangeTab} aria-label="ant example">
                        <AntTab label="Profile" />
                        <AntTab label="Notes" />
                        <AntTab label="Calibration" disabled={isManualEntry} />
                        <AntTab label="Medication" />
                        <AntTab label="Allergies" />
                    </AntTabs>
                    {selectedTab === 0 && (<AntTabs1 value={selectedTabProfile} onChange={handleChangeTabProfile} aria-label="ant example" variant="fullWidth">
                        <AntTab1 label="Patient Info" />
                        <AntTab1 label="Family Contact" />
                        <AntTab1 label="Provider Contact" />
                        <AntTab1 label="Facility Info" />
                    </AntTabs1>)}

                    {selectedTab === 1 && <Paper className={styles.patientInfoDiv} varient="outlined" elevation={2} style={{flexGrow: "1",  overflowY: 'scroll' }}>
                            <PatientNotes/>
                    </Paper>}

                    {selectedTab === 3 && <Paper className={styles.patientInfoDiv} varient="outlined" elevation={2} style={{ height: '36vh', overflowY: 'scroll' }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden" }}>
                            <div className={styles.patientInfoLabel1}>
                                No Medication data available.
                            </div>
                        </div>
                    </Paper>}

                    {selectedTab === 4 && <Paper className={styles.patientInfoDiv} varient="outlined" elevation={2} style={{ height: '36vh', overflowY: 'scroll' }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden" }}>
                            <div className={styles.patientInfoLabel1}>
                                No Allergy data available.
                            </div>
                        </div>
                    </Paper>}

                    {selectedTab === 0 && <Paper className={styles.patientInfoDiv} varient="outlined" elevation={2} style={{ maxHeight: '42vh', overflowY: 'scroll' }}>
                        {selectedTabProfile === 0 && (
                            <div className={styles.patientInfo1}>

                                <div className={styles.column1}>
                                    <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Preferred Name
                                        </div>
                                        <div className={styles.dataDiv}>
                                            {pccUserProfile.prefName ? pccUserProfile.prefName : ''}
                                        </div>
                                    </div>
                                    {/* <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Primary Condition <span style={{ color: 'red', paddingLeft: "0px" }}>*</span>
                                        </div>
                                        <div className={styles.dataDiv}>
                                            {pccUserProfile.diagnoses ? pccUserProfile.diagnoses[0] ? pccUserProfile.diagnoses[0].icdDesc ? pccUserProfile.diagnoses[0].icdDesc : '' : '' : ''}
                                        </div>
                                    </div> */}
                                </div>

                                <div className={styles.column2}>
                                    <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            DOB (YYYY-MM-DD)
                                        </div>
                                        <div className={styles.dataDiv}>
                                            {pccUserProfile.birthDate ? pccUserProfile.birthDate : ''}
                                        </div>
                                    </div>
                                    {/* <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Secondary Condition
                                        </div>
                                        <div className={styles.dataDiv}>
                                            {pccUserProfile.diagnoses ? pccUserProfile.diagnoses[1] ? pccUserProfile.diagnoses[1].icdDesc ? pccUserProfile.diagnoses[1].icdDesc : '' : '' : ''}
                                        </div>
                                    </div> */}
                                </div>

                                <div className={styles.column3}>
                                    <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Primary Language
                                        </div>
                                        <div className={styles.dataDiv}>
                                            {pccUserProfile.language ? pccUserProfile.language : ''}
                                        </div>
                                    </div>
                                    {/* <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Tertiary Condition
                                        </div>
                                        <div className={styles.dataDiv}>
                                            {pccUserProfile.diagnoses ? pccUserProfile.diagnoses[2] ? pccUserProfile.diagnoses[2].icdDesc ? pccUserProfile.diagnoses[2].icdDesc : '' : '' : ''}
                                        </div>
                                    </div> */}
                                </div>

                                <div className={styles.column4}>
                                    <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Interpreter Needed
                                        </div>
                                        <div className={styles.dataDiv}>
                                            {pccUserProfile.ipNeeded}
                                        </div>
                                    </div>
                                    {/* <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Other Comorbidities
                                        </div>
                                        <div className={styles.dataDiv}>
                                            {checkAndGetOtherComorbidities()}
                                        </div>
                                    </div> */}
                                </div>

                            </div>
                        )}

                        {selectedTabProfile === 1 && (
                            <div className={styles.patientInfo}>
                                {pccUserProfile.familyContacts.length > 0 && pccUserProfile.familyContacts.map((familyContact) => {
                                    //console.log('familyContact: ', familyContact);
                                    return (<>
                                        <div className={styles.patientInfoTitle}>
                                            <div className={styles.contHeader}>
                                                {familyContact.contactType ? familyContact.contactType.toString().includes('Tertiary') ? 'Tertiary' : familyContact.contactType.toString().includes('Secondary') ? 'Secondary' : 'Primary' : 'Primary'}
                                            </div>
                                        </div>

                                        <div className={styles.patientInfo1}>
                                            <div className={styles.column1}>
                                                <div className={styles.eachElement1}>
                                                    <div className={styles.eachSubElement}>
                                                        <div className={styles.labeldiv}>
                                                            Prefix
                                                        </div>
                                                        <div className={styles.dataDiv1}>
                                                            {familyContact.contactInfo.prefix}
                                                        </div>
                                                    </div>
                                                    <div className={styles.eachSubElement}>
                                                        <div className={styles.labeldiv}>
                                                            First Name
                                                        </div>
                                                        <div className={styles.dataDiv2}>
                                                            {familyContact.contactInfo.firstName}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.eachElement}>
                                                    <div className={styles.labeldiv}>
                                                        Sex at birth
                                                    </div>
                                                    <div className={styles.dataDiv}>
                                                        {familyContact.contactInfo.gender}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.column2}>
                                                <div className={styles.eachElement1}>
                                                    <div className={styles.eachSubElement}>
                                                        <div className={styles.labeldiv}>
                                                            Last Name
                                                        </div>
                                                        <div className={styles.dataDiv2}>
                                                            {familyContact.contactInfo.lastName}
                                                        </div>
                                                    </div>
                                                    <div className={styles.eachSubElement}>
                                                        <div className={styles.labeldiv}>
                                                            Suffix
                                                        </div>
                                                        <div className={styles.dataDiv1}>
                                                            {familyContact.contactInfo.suffix}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.eachElement}>
                                                    <div className={styles.labeldiv}>
                                                        Relationship to Patient <span style={{ color: 'red', paddingLeft: "0px" }}>*</span>
                                                    </div>
                                                    <div className={styles.dataDiv}>
                                                        {familyContact.relationship}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.column3}>
                                                <div className={styles.eachElement}>
                                                    <div className={styles.labeldiv}>
                                                        Email Address
                                                    </div>
                                                    <div className={styles.dataDiv}>
                                                        {familyContact.contactInfo.email}
                                                    </div>
                                                </div>
                                                <div className={styles.eachElement}>
                                                    <div className={styles.labeldiv}>
                                                        Contact Type
                                                    </div>
                                                    <div className={styles.dataDiv}>
                                                        {familyContact.contactType ? familyContact.contactType.substring(0, 20) + '...' : ''}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.column4}>
                                                <div className={styles.eachElement}>
                                                    <div className={styles.labeldiv}>
                                                        Phone Number
                                                    </div>
                                                    <div className={styles.dataDiv}>
                                                        {familyContact.contactInfo.email}
                                                    </div>
                                                </div>
                                                <div className={styles.eachElement}>
                                                    <div className={styles.labeldiv}>
                                                        Preferred mode of Communication
                                                    </div>
                                                    <div className={styles.dataDiv}>
                                                        {familyContact.contactInfo.prefCommMode}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>)
                                })}
                                {pccUserProfile.caregivers.length === 0 && (
                                    <div className={styles.patientInfoItem}>
                                        <div className={styles.patientInfoLabel}>
                                            No information available.
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {selectedTabProfile === 2 && (<div className={styles.patientInfo}>
                            {pccUserProfile.caregivers.length > 0 && pccUserProfile.caregivers.map((caregiver) => {
                                return (<>
                                    <div className={styles.patientInfoTitle}>
                                        <div className={styles.contHeader}>
                                            {caregiver.type.toString().includes('Tertiary') ? 'Tertiary' : caregiver.type.toString().includes('Secondary') ? 'Secondary' : 'Primary'}
                                        </div>
                                    </div>

                                    <div className={styles.patientInfo1}>
                                        <div className={styles.column1}>
                                            <div className={styles.eachElement1}>
                                                <div className={styles.eachSubElement}>
                                                    <div className={styles.labeldiv}>
                                                        Prefix
                                                    </div>
                                                    <div className={styles.dataDiv1}>
                                                        {caregiver.contactInfo.prefix}
                                                    </div>
                                                </div>
                                                <div className={styles.eachSubElement}>
                                                    <div className={styles.labeldiv}>
                                                        First Name
                                                    </div>
                                                    <div className={styles.dataDiv2}>
                                                        {caregiver.contactInfo.firstName}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.eachElement}>
                                                <div className={styles.labeldiv}>
                                                    Profession
                                                </div>
                                                <div className={styles.dataDiv}>
                                                    {caregiver.profession}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.column2}>
                                            <div className={styles.eachElement1}>
                                                <div className={styles.eachSubElement}>
                                                    <div className={styles.labeldiv}>
                                                        Last Name
                                                    </div>
                                                    <div className={styles.dataDiv2}>
                                                        {caregiver.contactInfo.lastName}
                                                    </div>
                                                </div>
                                                <div className={styles.eachSubElement}>
                                                    <div className={styles.labeldiv}>
                                                        Suffix
                                                    </div>
                                                    <div className={styles.dataDiv1}>
                                                        {caregiver.contactInfo.suffix}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.eachElement}>
                                                <div className={styles.labeldiv}>
                                                    NPI#
                                                </div>
                                                <div className={styles.dataDiv}>
                                                    {caregiver.contactInfo.npi}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.column3}>
                                            <div className={styles.eachElement}>
                                                <div className={styles.labeldiv}>
                                                    Email Address
                                                </div>
                                                <div className={styles.dataDiv}>
                                                    {caregiver.contactInfo.email}
                                                </div>
                                            </div>
                                            <div className={styles.eachElement}>
                                                <div className={styles.labeldiv}>
                                                    Speciality
                                                </div>
                                                <div className={styles.dataDiv}>
                                                    {caregiver.speciality}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.column4}>
                                            <div className={styles.eachElement}>
                                                <div className={styles.labeldiv}>
                                                    Phone Number
                                                </div>
                                                <div className={styles.dataDiv}>
                                                    {caregiver.contactInfo.phone}
                                                </div>
                                            </div>
                                            <div className={styles.eachElement}>
                                                <div className={styles.labeldiv}>
                                                    Preferred mode of Communication
                                                </div>
                                                <div className={styles.dataDiv}>
                                                    {caregiver.contactInfo.prefCommMode}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                            })}
                            {pccUserProfile.caregivers.length === 0 && (
                                <div className={styles.patientInfoItem}>
                                    <div className={styles.patientInfoLabel}>
                                        No information available.
                                    </div>
                                </div>
                            )}
                        </div>
                        )}

                        {selectedTabProfile === 3 && (<>
                            <div className={styles.editButtonDiv}>
                                <div className={!isFloorEditable ? styles.editButton : styles.editButtonClicked} onClick={event => editFacility(event)}>Edit</div>
                            </div>
                            <div className={styles.patientInfo1}>

                                <div className={styles.column1}>
                                    <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Facility Name
                                        </div>
                                        <div className={styles.dataDiv}>
                                            {pccUserProfile.facilityname}
                                        </div>
                                    </div>
                                    <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Floor <span style={{ color: 'red', paddingLeft: "0px" }}>*</span>
                                        </div>
                                        <div>
                                            <input type="text" value={floor} onChange={(e) => setFloor(e.target.value)}
                                                className={!isFloorEditable ? styles.dataDiv : styles.dataDivEdit}
                                                disabled={!isFloorEditable} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Barcode
                                        </div>
                                        <div>
                                            <input type="text" value={barcode} onChange={(e) => setBarcode(e.target.value)}
                                                className={!isFloorEditable ? styles.dataDiv : styles.dataDivEdit}
                                                disabled={!isFloorEditable} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.column2}>
                                    <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Facility Number
                                        </div>
                                        <div className={styles.dataDiv}>
                                            {pccUserProfile.facilitykey}
                                        </div>
                                    </div>
                                    <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Unit/Wing/Zone <span style={{ color: 'red', paddingLeft: "0px" }}>*</span>
                                        </div>
                                        <div>
                                            <input value={unit} onChange={(e) => setUnit(e.currentTarget.value)} className={!isFloorEditable ? styles.dataDiv : styles.dataDivEdit}
                                                disabled={!isFloorEditable} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.column3}>
                                    <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Facility Type
                                        </div>
                                        <div className={styles.dataDiv}>
                                            {pccUserProfile.facility_type}
                                        </div>
                                    </div>
                                    <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Room Number <span style={{ color: 'red', paddingLeft: "0px" }}>*</span>
                                        </div>
                                        <div>
                                            <input value={room} onChange={(e) => setRoom(e.currentTarget.value)} className={!isFloorEditable ? styles.dataDiv : styles.dataDivEdit}
                                                disabled={!isFloorEditable} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.column4}>
                                    <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Action Code
                                        </div>
                                        <div className={styles.dataDiv}>
                                            {pccUserProfile.facilitycode}
                                        </div>
                                    </div>
                                    <div className={styles.eachElement}>
                                        <div className={styles.labeldiv}>
                                            Bed Number <span style={{ color: 'red', paddingLeft: "0px" }}>*</span>
                                        </div>
                                        <div>
                                            <input type="text" value={bed} onChange={(e) => setBed(e.currentTarget.value)} className={!isFloorEditable ? styles.dataDiv : styles.dataDivEdit}
                                                disabled={!isFloorEditable} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showSaveCancelForFacility ?
                                (<>
                                    <div className={styles.saveButtonDiv}>
                                        <div className={styles.skipButton} onClick={() => hideCancelSaveButtons()}>
                                            Cancel
                                        </div>
                                        <div className={saveFacilityDisabled ? styles.saveButtonDisabled : styles.saveButton} onClick={() => updateFacility()}>
                                            Save
                                        </div>
                                    </div>
                                </>) : (<></>)}
                        </>)}
                    </Paper>}

                    {selectedTab === 2 && <Paper className={styles.patientInfoDiv2} varient="outlined" elevation={2} style={{ maxHeight: '43vh', overflowY: 'scroll' }} >
                        <BpCalibrationND></BpCalibrationND>
                    </Paper>}
                </Paper>
                {showFooterButtons && (<div className={styles.footerDiv}>
                    {isWarningShown && selectedML !== '--' && (<div className={styles.warningDiv}>
                        <div className={styles.warningIcon}>
                            <WarningIcon sx={{ height: '10px !important', width: '10px !important', color: "#FFFFFF" }}></WarningIcon>
                        </div>
                        Do not select "MouthLab" if you still see a blue light on the status bar.
                        {/* <div className={styles.questionIcon}>
                            <QuestionMarkIcon sx={{ height: '14px !important', width: '14px !important', color: "#4E253A" }}></QuestionMarkIcon>
                        </div> */}
                    </div>)}
                    <div className={styles.footerButtonsDiv}>
                        <div className={styles.footerButtons} onMouseEnter={() => setIsWarningShown(true)} onMouseLeave={() => setIsWarningShown(false)}>
                            {selectedML !== '--' && (<div className={styles.pairButton} onClick={(event) => handlePairAndMeasure(event)}><MouthlabIcon style={{ height: '28px !important', width: '28px !important', color: "#F14C00" }}></MouthlabIcon>MouthLab</div>)}
                            <div className={styles.enterManuallyButton} onClick={(event) => handleManualEntry(event)}><div className={styles.touchIconWrapper}><TouchAppIcon style={{ height: '30px !important', width: '30px !important', color: "#4E253A" }}></TouchAppIcon></div> <div className={styles.manualLabel}>Manual</div> </div>
                        </div>
                    </div>
                </div >)}

                <QMPannel ref={ref} pccUserProfile={pccUserProfile} />
                <MEPannel ref={ref2} pccUserProfile={pccUserProfile} />
                <CalibrationMeasurementPannel pccUserProfile={pccUserProfile} />

                {/* MouthLab removal from subject */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={styles.modal}
                    open={showDialog}
                    onClose={(event, reason) => closeDialogMenu(event, reason)}
                    hideBackdrop={true}
                >
                    <div className={styles.paperUnassignML}>
                        <div className={styles.menuTitle}>Change MouthLab</div>

                        <div className={styles.userQuestionMessage}> Are you sure you want to change the selected MouthLab device? </div>

                        <div className={styles.mouthLabImgDiv}>
                            <img src={MLImg} loading="lazy" className={styles.mlImg} />
                        </div>

                        <div className={styles.buttonContainer}>
                            <button type="button" onClick={closeDialogMenu} className={styles.cancelButton}>
                                Cancel
                            </button>
                            <button type="submit" onClick={handleSubmit} className={styles.submitButton}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </Modal>

                {/* MouthLab removal from subject */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={styles.modal}
                    open={showDialogforUserPaired}
                    onClose={(event, reason) => closeDialogDialogforUserPaired(event, reason)}
                    hideBackdrop={true}
                >
                    <div className={styles.paperUnassignML}>
                        <div className={styles.menuTitle}>Warning</div>

                        <div className={styles.userQuestionMessage1}>
                            This subject is already assigned to device ML {currUserDeviceId}. Do you want to unassign the device?
                        </div>

                        {!isProcessing && <div className={styles.mouthLabImgDiv}>
                            <img src={WarningImg} loading="lazy" className={styles.warningImg} />
                        </div>}

                        {isProcessing && (showUALoader ? (<>
                            <div className={styles.circularProgressPopup}>
                                <CircularProgress thickness={5.0} />
                            </div>
                        </>) : (<div className={styles.uaSuccessMsg}>{uaMsg}</div>))}

                        <div className={styles.buttonContainer}>
                            <button onClick={closeDialogDialogforUserPaired} className={styles.cancelButton}>
                                No
                            </button>
                            <button onClick={unassignTheMl} className={styles.submitButton}>
                                Yes
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}