import { put, takeLatest } from "redux-saga/effects";
import * as dashboardActions from "../Actions/DashboardActions";
// import axios from "axios";
import Transport from "../Transport/AidarTransport";
import { setShowLoader } from "../Actions/UtilActions";
import domainCF from "../Components/API/domainCF";
import { dashboardConstants } from "../Constants/DashboardConstants";

function* getUserProfileSaga(action) {
    var token = sessionStorage.getItem("IdToken");
    const headers = {
        "Authorization": `Bearer ${token}`,
    };
    const POST_USER_INFO_FROM_EMAIL = `${domainCF}/api/getUserProfile`;

    try {
        // const response = yield axios.post(POST_USER_INFO_FROM_EMAIL, action.payload, { headers });
        const response = yield Transport.genericOperation(
            POST_USER_INFO_FROM_EMAIL,
            headers,
            {
                email: action.payload
            },
            "POST"
        );

        if (!response || !response.userProfile) {
            let responseWithOnlyEmail = {
                email: action.payload,
                noProjLoc:response?.noProjLoc
            };
            yield put(dashboardActions.getUserProfileSuccess(responseWithOnlyEmail));
        } else {
            console.log("getUserProfile response: ", response);
            yield put(dashboardActions.getUserProfileSuccess(response.userProfile));
        }

        yield put(setShowLoader(false));
    } catch (e) {
        yield put(setShowLoader(false));
        console.error("getChildInfoSaga Saga ERROR ", e);
        throw e;
    }

    

}
export default function* DashboardSaga() {
    try {
        yield takeLatest(dashboardActions.getUserProfile, getUserProfileSaga);
    } catch {
        yield;
    }
}