import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { globalConstants } from "../../../../Constants/GlobalConstants";
import { getUserRole } from "../../../../Reducer/selectors/DashboardSelector";
import { setPCCPatientListPageNumber, setPCCPatientListPageSize, setSelectedMlId } from "../../../../Actions";
import { getUserProfile } from "../../../../Reducer/selectors/DashboardSelector.js";
import * as actions from "../../../../Actions";
import { UserContext } from "../../../Store/UserContext.js";
import styles from "./Dashboard.module.css";


function Dashboard() {
  // const userRole = useSelector(getUserRole);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currUserProfile = useSelector(getUserProfile,shallowEqual);
  //  const currUserEmail = currUserProfile.email;
  const userRole = currUserProfile.role;
  //  console.log("email from redux state: ", currUserProfile);
  /* useEffect(() => {
        if(userRole === globalConstants.USER_ROLE_NURSE_AID){
            navigate(`/deviceAssignments`, { replace: true }); 
        }else if(userRole === globalConstants.USER_ROLE_PATIENT){
            console.log('patient... ')
            navigate(`/subjectquickview`, { replace: true }); 
        }else {
            navigate(`/projectlocations`, { replace: true }); 
        }
    
     }, []); */

  useEffect(() => {
    if (!currUserProfile.firstName && currUserProfile.email ) {
      dispatch(actions.getUserProfile(currUserProfile.email));
    }
  }, [currUserProfile.firstName]);


  const { setUserInformation } = useContext(UserContext);

  useEffect(() => {
    if (currUserProfile && currUserProfile.firstName) {
      setUserInformation({
        email: currUserProfile.email,
        firstName: currUserProfile.firstName,
        lastName: currUserProfile.lastName,
        institutionId: currUserProfile.institutionId,
        projects: currUserProfile.projects,
        locations: currUserProfile.locations,
        selectedProjectId: "",
        selectedLocationId: "",
      });
    }
  }, [currUserProfile]);

  useEffect(() => {
    if(currUserProfile && currUserProfile.firstName && currUserProfile.projects && currUserProfile.projects?.length>0)
    {
      navigate(`/deviceAssignments`, { replace: true });
    }
  },[currUserProfile.projects])

  // useEffect(() => {
  //   //let userRole = currUserProfile.role;
  //   console.log("THE ROLE OF THE USER LOGGIN IN: ", userRole);
  //   navigate(`/deviceAssignments`, { replace: true });
  // }, []);
  return <div>
    { currUserProfile?.noProjLoc === true && (
      <div className={styles.containerForMessage}>
        <div className={styles.msgNoIPL}>
          You are yet to be assigned to a Project. <br />
          For further assistance, please call <span className={styles.phoneNum}>+1(443)875-6456</span>{" "}
        </div>
      </div> 
    )}
  </div>;
}
export default Dashboard;

