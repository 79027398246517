import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./PatientNotes.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { format } from "date-fns";
import { DateContext } from "../../../Store/DateProvider";
import Button from "@mui/material/Button";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getPatientNotesSelector } from "../../../../Reducer/selectors/NotesSelector";
import { getSelectedSubjectId, getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";
import { setOpenAddNotesPanel, setShowNotesDrawer, setShowNotesPanel } from "../../../../Actions";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { getPatientNotes } from "../../../../Actions";
import subDays from "date-fns/subDays";
import Input from '@material-ui/core/Input';
import SendIcon from '@mui/icons-material/Send';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { updatePatientNote } from "../../../../Actions";
import { selectedRecordIdForNotesSelector, getSelectedNotesTimestamp } from "../../../../Reducer/selectors/NotesSelector";
import { useParams } from "react-router-dom";
import GetPatientInfo from "../../../API/GetPatientInfo/GetPatientInfo";
import { setShowLoader, setShowPatientNotesLoader } from "../../../../Actions/UtilActions";
import { postPatientNotes } from "../../../../Actions";
import { getPageLoader, getPatientNotesLoader } from "../../../../Reducer/selectors/UtilSelector";
import { CircularProgress } from "@material-ui/core";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export default function PatientNotes() {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const dispatch = useDispatch();
    const [isCalOpen, setIsCalOpen] = useState(false);
    const patientNotes = useSelector(getPatientNotesSelector);
    console.log('patientNotes: ', patientNotes);
    const [notes, setNotes] = useState([]);
    const selectedSubjectId = useSelector(getSelectedSubjectId);
    const refOne = useRef(null);
    const [newNotes, setNewNotes] = useState("");
    const [tagNames, setTagNames] = useState("");
    const [isAdherenceSelected, setIsAdherenceSelected] = useState(false);
    const [isMedicationSelected, setIsMedicationSelected] = useState(false);
    const [isVitalsSelected, setIsVitalsSelected] = useState(false);
    const [isDeviceSelected, setIsDeviceSelected] = useState(false);
    const [isOthersSelected, setIsOthersSelected] = useState(false);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [searched, setSearched] = useState("");
    const selectedRecordId = useSelector(selectedRecordIdForNotesSelector);
    const [patientDetailInfo, setPatientDetailInfo] = useState({});
    const userProfile = useSelector(getUserProfile);
    const [firstName] = useState(userProfile.firstName);
    const [lastName] = useState(userProfile.lastName);
    const userName = firstName + " " + lastName;
    const userId = userProfile.id;
    let curPatientId = useParams();
    const loader = useSelector(getPatientNotesLoader);
    const timeZoneFromDayjs = dayjs.tz.guess();

    useEffect(() => {
        dispatch(setOpenAddNotesPanel(false));
        dispatch(setShowNotesDrawer(false));
        dispatch(setShowNotesPanel(false));
        dispatch(setShowPatientNotesLoader(true));
        const payload = {
            subjectId: selectedSubjectId.selectedSubjectId,
            recordId: ''
        }
        dispatch(getPatientNotes(payload));
        dispatch(setOpenAddNotesPanel(false))
    }, []);

    useEffect(() => {
        setNotes(patientNotes);
    }, [patientNotes]);

    useEffect(() => {
        GetPatientInfo(curPatientId.patientId).then((res) => {
            setPatientDetailInfo(res);
        });
    }, [curPatientId.patientId]);

    const [dateRangeApplied, setDateRangeApplied] = useState([
        {
            startDate: subDays(new Date(), 30),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const [dateRangeSelected, setDateRangeSelected] = useState(dateRangeApplied);

    const useStyles = makeStyles(() => ({
        tooltip: {
            marginTop: 5,
            font: "12px",
            background: "black",
            fontFamily: "GTWalsheimProRegular",
        },
    }));

    const classes = useStyles();

    const handleCalClick = (event) => {
        event.preventDefault();
        if (isCalOpen) {
            setDateRangeSelected(dateRangeApplied);
            setIsCalOpen(false);
        } else {
            setDateRangeApplied(dateRangeSelected);
            setIsCalOpen(true);
        }
    };

    const fetchPatientNotes = () => {
        setIsCalOpen(false);
        dispatch(setShowPatientNotesLoader(true));
        const payload = {
            subjectId: selectedSubjectId.selectedSubjectId,
            recordId: ''
        }
        dispatch(getPatientNotes(payload));
    }

    // Hide dropdown on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setIsCalOpen(false);
        }
    };

    const handleCalChanges = (item) => {
        setDateRangeSelected([item.selection]);
    };

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key === "Escape") {
            setIsCalOpen(false);
        }
    };

    /*  const handleAddTag = (tag) => {
         if (tagNames.includes(tag)) {
             const filteredTags = tagNames.filter((item) => item !== tag);
             setTagNames(filteredTags);
         } else {
             setTagNames([...tagNames, tag]);
         }
     } */

    const handleAddTagAdherence = (event) => {
        event.preventDefault();
        setIsAdherenceSelected(!isAdherenceSelected);
        setTagNames("Adherence");
        /* if (tagNames.includes("Adherence")) {
            const filteredTags = tagNames.filter((item) => item !== "Adherence");
            setTagNames(filteredTags);
        } else {
            setTagNames([...tagNames, "Adherence"]);
        } */
    }

    const handleAddTagMedication = (event) => {
        event.preventDefault();
        setIsMedicationSelected(!isMedicationSelected);
        setTagNames("Medication");
        /* if (tagNames.includes("Medication")) {
            const filteredTags = tagNames.filter((item) => item !== "Medication");
            setTagNames(filteredTags);
        } else {
            setTagNames([...tagNames, "Medication"]);
        } */
    }

    const handleAddTagDevice = (event) => {
        event.preventDefault();
        setIsDeviceSelected(!isDeviceSelected);
        setTagNames("Device");
        /* if (tagNames.includes("Device")) {
            const filteredTags = tagNames.filter((item) => item !== "Device");
            setTagNames(filteredTags);
        } else {
            setTagNames([...tagNames, "Device"]);
        } */
    }

    const handleAddTagOthers = (event) => {
        event.preventDefault();
        setIsOthersSelected(!isOthersSelected);
        setTagNames("Others");
        /* if (tagNames.includes("Others")) {
            const filteredTags = tagNames.filter((item) => item !== "Others");
            setTagNames(filteredTags);
        } else {
            setTagNames([...tagNames, "Others"]);
        } */
    }

    const handleAddTagVitals = (event) => {
        event.preventDefault();
        setIsVitalsSelected(!isVitalsSelected);
        setTagNames("Vitals");
        /* if (tagNames.includes("Vitals")) {
            const filteredTags = tagNames.filter((item) => item !== "Vitals");
            setTagNames(filteredTags);
        } else {
            setTagNames([...tagNames, "Vitals"]);
        } */
    }

    const handleCheckBookmark = (event) => {
        event.preventDefault();
        setIsBookmarked(!isBookmarked);
        console.log("ISBOOKMARK: ", isBookmarked);
    }

    const searchNotes = (event) => {
        setSearched(event.target.value);
        let searchValue = event.target.value;
        let filteredNotes = patientNotes;

        if (!searchValue || searchValue.length === 0) {
            setNotes(filteredNotes);
        } else {
            filteredNotes = patientNotes && patientNotes.filter((note) => (note.note.toLowerCase().includes(searchValue.toLowerCase()) || (note.createdBy ? note.createdBy.toLowerCase().includes(searchValue.toLowerCase()) : false)));
            setNotes(filteredNotes);
        }
    }

    const handleCancleSearch = (event) => {
        event.preventDefault();
        setSearched("");
        setNotes(patientNotes);
    }

    const handleBookmarking = (event, notes) => {
        event.preventDefault();
        updateNote(notes);
    }

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const updateNote = (notes) => {
        const payload = {
            id: notes.id,
            subjectId: notes.subjectId,
            recordId: notes.recordId,
            recordTimestamp: notes.recordTimestamp,
            createdDateTime: new Date(),
            createdBy: userId,
            note: notes.note,
            isBookmarked: notes.isBookmarked == "false" ? 1 : 0,
            tags: notes.tags,
            timezoneStr: timeZone,
        }
        dispatch(updatePatientNote(payload));
    }

    const resetTags = () => {
        setIsAdherenceSelected(false);
        setIsDeviceSelected(false);
        setIsMedicationSelected(false);
        setIsVitalsSelected(false);
        setIsOthersSelected(false);
        setIsBookmarked(false);
    }

    const submitNotes = () => {
        if (newNotes.length <= 0 || newNotes.length > 1000) {
            return;
        } else {
            const payload = {
                subjectId: selectedSubjectId.selectedSubjectId,
                recordId: selectedRecordId,
                createdBy: userId,
                note: newNotes,
                isBookmarked: isBookmarked,
                tags: tagNames,
                createdDateTime: new Date(),
                timezoneStr: timeZone,
            }
            dispatch(setShowPatientNotesLoader(true));
            dispatch(postPatientNotes(payload));
            setNewNotes("");
            resetTags();
            setIsBookmarked(false);
        }
    }

    function ColorfulTagsGen(props) {

        if (!props.tags || props.tags.length === 0) {
            return (
                <></>
            );
        }
        else {
            return (
                <>
                    {/* props.tags.map(tag => ( */
                        props.tags === "Adherence" ?
                            (<div className={styles.selectedTagAdherence} key={props.tags}> {props.tags} </div>) :
                            props.tags === "Medication" ?
                                (<div className={styles.selectedTagMedication} key={props.tags}> {props.tags} </div>) :
                                props.tags === "Vitals" ?
                                    (<div className={styles.selectedTagVitals} key={props.tags}> {props.tags} </div>) :
                                    props.tags === "Device" ?
                                        (<div className={styles.selectedTagDevice} key={props.tags}> {props.tags} </div>) :
                                        props.tags === "Others" ?
                                            (<div className={styles.selectedTagOthers} key={props.tags}> {props.tags} </div>) :
                                            (<div className={styles.selectedTagName} key={props.tags}> {props.tags} </div>) //))
                    }
                </>
            );
        }
    }

    return (
        <div className={styles.rootDiv}>
            {/* <div className={styles.containerDiv}>
                <div className={styles.datePickerDiv}>
                    <Tooltip title="Choose another date range" classes={{ tooltip: classes.tooltip }}>
                        <CalendarMonthIcon onClick={(event) => handleCalClick(event)}></CalendarMonthIcon>
                    </Tooltip>
                    <div className={styles.dateInputDiv}>
                        <input
                            value={`${format(dateRangeSelected[0].startDate, "MM/dd/yyyy")}  |  ${format(dateRangeSelected[0].endDate, "MM/dd/yyyy")}`}
                            readOnly
                            className={styles.dateInput}
                            onClick={(event) => handleCalClick(event)}
                        />
                    </div>
                </div>
                {isCalOpen && (
                    <div className={styles.datePickerWrapper} ref={refOne}>
                        <DateRangePicker
                            onChange={(item) => handleCalChanges(item)}
                            editableDateInputs={false}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={dateRangeSelected}
                            direction="horizontal"
                            maxDate={addDays(new Date(), 1)}
                            rangeColors={["#4E253A"]}
                        />
                        <div className={styles.applyButtonDiv}>
                            <div className={styles.applyButton} onClick={(event) => handleCalClick(event)}> Cancel </div>
                            <div className={styles.applyButton} onClick={() => fetchPatientNotes()}> Apply </div>
                        </div>
                    </div>
                )}
            </div> */}


            {/* {!searched && <div className={styles.allNotesHeader}>
                All Notes
            </div>} */}

            <Input
                className={styles.searchBar}
                type="text"
                placeholder="Search Notes"
                value={searched}
                onChange={(event) => setSearched(event.target.value)}
                onKeyUp={(event) => searchNotes(event)}
                startAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            size="small"
                        >
                            <SearchIcon className={styles.searchIcon}></SearchIcon>
                        </IconButton>
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        {<IconButton
                            className={styles.searchIcon}
                        >
                            {searched === "" ? <></> : <CloseIcon style={{ color: "#4E253A" }} onClick={(event) => handleCancleSearch(event)}></CloseIcon>}
                        </IconButton>}
                    </InputAdornment>
                }
                disableUnderline="true"
                style={{
                    fontFamily: "GTWalsheimProRegular"
                }}
            />
            <div className={styles.allNotesDiv}>
                <div className={styles.showNotesDiv}>
                    {loader ? (<div className={styles.circularProgressDiv} style={{ backgroundColor: `#FFF6FC`, height: `100%`, width: `100%` }}>
                        <CircularProgress thickness={5.0} style={{ color: `#4E253A` }}></CircularProgress>
                    </div>) : (notes && notes.length > 0) ? (notes.map((note) =>
                        <div className={styles.noteBlock}>
                            <div className={styles.noteHeader}>
                                <div className={styles.createdDiv}>
                                    <div className={styles.createdBy}>
                                        <IconButton
                                            size="small"
                                            onClick={(event) => handleBookmarking(event, note)}
                                        >
                                            {note.isBookmarked === "true" ? (<BookmarkOutlinedIcon className={styles.pinButton}></BookmarkOutlinedIcon>) : (<BookmarkBorderOutlinedIcon className={styles.pinButton}></BookmarkBorderOutlinedIcon>)}
                                        </IconButton>
                                        {note.createdBy}
                                    </div>
                                    <div className={styles.createdTime}>
                                        {dayjs.utc(note.createdDateTime).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}
                                    </div>
                                </div>
                                <div className={styles.noteTags}>
                                    <ColorfulTagsGen tags={note.tags} />
                                </div>
                            </div>
                            <div className={styles.noteTextArea}>
                                <Input
                                    className={styles.notesDisplayer}
                                    value={note.note}
                                    multiline={true}
                                    readOnly={true}
                                    disableUnderline="true"
                                />

                                <div className={styles.noteFooter}>
                                    {/* {note.recordTimestamp ? <div className={styles.noteMeasurementTime}>
                                        {dayjs.utc(note.recordTimestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}
                                    </div> : <></>
                                    } */}
                                </div>
                            </div>
                        </div>)) : (
                        <div style={{
                            display: "flex",
                            paddingTop: "60px",
                            paddingBottom: "0px",
                            fontFamily: "GTWalsheimProRegular",
                            fontSize: "26px",
                            fontWeight: "bold",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            color: "#87668B",
                            overflowY: "hidden"
                        }}>
                            No notes available!
                        </div>
                    )}
                </div>
                <div className={styles.addNewNoteDiv}>
                    <div className={styles.dummyWrapper}>
                        <div className={styles.newNotesWrapper}>
                            <Input
                                className={styles.notesEditor}
                                type="text"
                                placeholder="Start typing..."
                                value={newNotes}
                                multiline={true}
                                maxRows={5}
                                minRows={1}
                                onChange={(event) => setNewNotes(event.target.value)}
                                disableUnderline="true"
                            />
                            <div className={styles.newDumDiv}>
                                <div className={styles.addNoteButtons}>
                                    <IconButton
                                        size="small"
                                        className={styles.addNotesIcon}
                                        onClick={(event) => handleCheckBookmark(event)}
                                    >
                                        {isBookmarked ? (<BookmarkOutlinedIcon style={{ color: "#87668B", height: '20px', width: '20px' }}></BookmarkOutlinedIcon>) : (<BookmarkBorderOutlinedIcon style={{ color: "#87668B", height: '20px', width: '20px' }}></BookmarkBorderOutlinedIcon>)}
                                    </IconButton>
                                </div>
                                <div className={styles.characterLimitDiv}>
                                    {<div className={(newNotes.length <= 0 || newNotes.length > 1000) ? styles.characterCountRed : styles.characterCount}>{newNotes.length}</div>}/1000</div>
                            </div>
                        </div>

                        {/*&<div className={styles.selectTagsDiv}>
                            <IconButton
                                size="small"
                                onClick={(event) => handleAddTagAdherence(event)}
                            >
                                <div className={isAdherenceSelected ? styles.selectedTagAdherence1 : styles.tagNameAdherence}>
                                    Adherence
                                </div>
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={(event) => handleAddTagMedication(event)}
                            >
                                <div className={isMedicationSelected ? styles.selectedTagMedication1 : styles.tagNameMedication}>
                                    Medication
                                </div>
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={(event) => handleAddTagVitals(event)}
                            >
                                <div className={isVitalsSelected ? styles.selectedTagVitals1 : styles.tagNameVitals}>
                                    Vitals
                                </div>
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={(event) => handleAddTagDevice(event)}
                            >
                                <div className={isDeviceSelected ? styles.selectedTagDevice1 : styles.tagNameDevice}>
                                    Device
                                </div>
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={(event) => handleAddTagOthers(event)}
                            >
                                <div className={isOthersSelected ? styles.selectedTagOthers1 : styles.tagNameOthers}>
                                    Others
                                </div>
                            </IconButton>
                        </div>*/}

                        <div disabled={(newNotes.length <= 0 || newNotes.length > 1000)} onClick={() => submitNotes()} className={(newNotes.length <= 0 || newNotes.length > 1000) ? styles.submitNotesButtonDisabled : styles.submitNotesButton}>Submit</div>

                    </div>

                </div>
            </div>
        </div>
    );
}