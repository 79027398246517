import React, {
    forwardRef,
    useImperativeHandle,
    useEffect,
    useRef,
    useState,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import SensorsIcon from '@mui/icons-material/Sensors';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import Avatar from "@material-ui/core/Avatar";
import ProfileIcon from "../../../../Assets/Images/ProfileIcon.png";
import styles from "./MEPannel.module.css";
import Backdrop from '@mui/material/Backdrop';
import {
    getPCCUserProfileSelector,
    getShowMEDrawer,
    getPrevMeasurement,
    getUploadManualEntryStatus
} from "../../../../Reducer/selectors/NursingDashboardSelector";
import { getSelectedPCCPatientSelector } from "../../../../Reducer/selectors/DashboardSelector";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Input from '@material-ui/core/Input';
import {
    setShowMEDrawer,
    setUploadManualEntryStatus,
    updateExtraMeasurementParams,
    uploadManualEntry
} from "../../../../Actions";
import { getUserProfile } from "../../../../Reducer/selectors/DashboardSelector.js";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { ReactComponent as TempIcon } from "../../../../Assets/icons/01-Temp.svg";
import { ReactComponent as BPIcon } from "../../../../Assets/icons/02-BP.svg";
import { ReactComponent as RRIcon } from "../../../../Assets/icons/03-RR.svg";
import { ReactComponent as PRIcon } from "../../../../Assets/icons/04-PR.svg";
import { ReactComponent as HRIcon } from "../../../../Assets/icons/05-HR.svg";
import { ReactComponent as SPOIcon } from "../../../../Assets/icons/06-SpO2.svg";
import { ReactComponent as HRVIcon } from "../../../../Assets/icons/07-HRV.svg";
import { ReactComponent as PEFIcon } from "../../../../Assets/icons/08-PEF.svg";
import { ReactComponent as FEVIcon } from "../../../../Assets/icons/09-FEV1.svg";
import { ReactComponent as GlucoseIcon } from "../../../../Assets/icons/Glcouse-Icon.svg";
import { ReactComponent as WeightIcon } from "../../../../Assets/icons/Weight-Icon.svg";
import { CircularProgress } from "@material-ui/core";

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

dayjs.extend(utc);
dayjs.extend(timezone);
const drawerWidth = 600;
const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        backgroundColor: "#f4e7ee",
        width: drawerWidth,
    },
    typoStyle: {
        fontSize: 20,
        textTransform: `capitalize;`,
        fontFamily: `GTWalsheimProRegular`,
        width: 100,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        justifyContent: "flex-start",
        backgroundColor: "#E4DFE9",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const MEPannel = forwardRef((props, ref) => {

    const refOne = useRef(null);
    const classesQV = useStyles();
    const dispatch = useDispatch();
    const isMEOpen = useSelector(getShowMEDrawer);
    const [stepNumber, setStepNumber] = useState(1);
    const selectedPCCPatient = useSelector(getSelectedPCCPatientSelector);
    const selectedPCCPatientProfile = useSelector(getPCCUserProfileSelector);
    const [newNotes, setNewNotes] = useState("");
    const [measurementSuccess, setMeasurementSuccess] = useState(false);
    const manualEntryStatus = useSelector(getUploadManualEntryStatus);
    const [showLoader, setShowLoader] = useState(false);
    const [displayStatus, setDisplayStatus] = useState(false);
    const userProfile = useSelector(getUserProfile);
    const [firstName] = useState(userProfile.firstName);
    const [lastName] = useState(userProfile.lastName);
    const userName = firstName + " " + lastName;


    const timeZoneFromDayjs = dayjs.tz.guess();

    const handleQuickDrawerOpen = () => {
        dispatch(setShowMEDrawer(true));
        setTimestamp(new Date());
    };

    const resetMeasurements = () => {
        setPainLevel();
        setWeight("");
        setGlucoseLevel("");
        setTemp("");
        setSpo2("");
        setBreathingRate("");
        setPulseRate("");
        setHeartRate("");
        setHrv("");
        setSbp("");
        setdbp("");
        setFev1("");
        setPef("");
        setNewNotes("");
        setErrors({});
        setAttemptedSubmit(false);
    }

    const retakeMeasurements = () => {
        setStepNumber(1);
        resetMeasurements();
        setDisplayStatus(false);
        setTimestamp(new Date());
    }

    const handleQuickDrawerClose = () => {
        dispatch(setShowMEDrawer(false));
        setStepNumber(1);
        resetMeasurements();
        setDisplayStatus(false);
    };

    const handleSubmitFinalMeasurements = () => {
        setAttemptedSubmit(true);
        if (Object.keys(errors).length > 0) {
            return;
        }
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        setShowLoader(true);
        const payload = {
            subjectId: selectedPCCPatientProfile.subjectId,
            temperature: temp === "" ? undefined : "\"" + temp + ", 1.00\"",
            spo2: spo2 === "" ? undefined : "\"" + spo2 + ", 1.00\"",
            breathingRate: breathingRate === "" ? undefined : "\"" + breathingRate + ", 1.00\"",
            pulseRate: pulseRate === "" ? undefined : "\"" + pulseRate + ", 1.00\"",
            heartRate: heartRate === "" ? undefined : "\"" + heartRate + ", 1.00\"",
            hrv: hrv === "" ? undefined : "\"" + hrv + ", 1.00\"",
            sbp: sbp === "" ? undefined : "\"" + sbp + ", 1.00\"",
            dbp: dbp === "" ? undefined : "\"" + dbp + ", 1.00\"",
            fev1: fev1 === "" ? undefined : "\"" + fev1 + ", 1.00\"",
            pef: pef === "" ? undefined : "\"" + pef + ", 1.00\"",
            weight: weight === "" ? undefined : weight,
            glucose: glucoseLevel === "" ? undefined : glucoseLevel,
            painLevel: painLevel === "" ? undefined : painLevel,
            tsRecorded: useManualTime ? dayjs.tz(
                `${manualDateTime.format('YYYY-MM-DDTHH:mm:ss')}`,
                manualTimezone
            ).utc().toISOString() : timestamp,
            timezone: useManualTime ? manualTimezone : timeZone,
            note: newNotes,
            createdBy: userProfile?.id,
        }
        console.log("MANUAL ENTRY PAYLOAD: ", payload);
        dispatch(uploadManualEntry(payload));
        closeLoaderIn5Seconds();
    }

    const [painLevel, setPainLevel] = useState();
    const [weight, setWeight] = useState("");
    const [glucoseLevel, setGlucoseLevel] = useState("");
    const [timestamp, setTimestamp] = useState(new Date());
    const [temp, setTemp] = useState("");
    const [spo2, setSpo2] = useState("");
    const [breathingRate, setBreathingRate] = useState("");
    const [pulseRate, setPulseRate] = useState("");
    const [heartRate, setHeartRate] = useState("");
    const [hrv, setHrv] = useState("");
    const [sbp, setSbp] = useState("");
    const [dbp, setdbp] = useState("");
    const [fev1, setFev1] = useState("");
    const [pef, setPef] = useState("");
    const [useManualTime, setUseManualTime] = useState(false);
    const [manualDateTime, setManualDateTime] = useState(dayjs());
    const [manualTimezone, setManualTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const maxDate = dayjs().tz(manualTimezone);
    const minDate = dayjs().tz(manualTimezone).subtract(7, 'day');

    const [errors, setErrors] = useState({});
    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const timezoneOptions = [
        { label: 'Eastern Time (US & Canada)', value: 'America/New_York' },
        { label: 'Central Time (US & Canada)', value: 'America/Chicago' },
        { label: 'Pacific Time (US & Canada)', value: 'America/Los_Angeles' },
        { label: 'India Standard Time', value: 'Asia/Kolkata' }
    ];

    const handleUseManualTimeChange = (event) => {
        setUseManualTime(event.target.checked);
    };

    const validateAllMeasurements = () => {
        const newErrors = {};

        const adjustedDateTime = dayjs.tz(
            `${manualDateTime.format('YYYY-MM-DDTHH:mm:ss')}`,
            manualTimezone
        );

        if (useManualTime) {
            // Validate the adjusted date
            if (adjustedDateTime.isAfter(maxDate)) {
                newErrors.date = "The selected time is in the future.";
            } else if (adjustedDateTime.isBefore(minDate)) {
                newErrors.date = "The selected time is more than 7 days in the past.";
            }
        }
        // Validate temp
        if (temp !== "") {
            const numValue = parseFloat(temp);
            if (isNaN(numValue) || numValue < 94 || numValue > 109) {
                newErrors.Temp = "Temperature must be between 94 and 109";
            }
        }

        // Validate sbp and dbp
        if (sbp !== "") {
            const numValue = parseInt(sbp, 10);
            if (isNaN(numValue) || numValue < 50 || numValue > 250) {
                newErrors.SBP = "Systolic must be between 50 and 250";
            }
            if (dbp === "") {
                newErrors.DBP = "Diastolic is required if Systolic is present";
            }
        }
        if (dbp !== "") {
            const numValue = parseInt(dbp, 10);
            if (isNaN(numValue) || numValue < 30 || numValue > 150) {
                newErrors.DBP = "Diastolic must be between 30 and 150";
            }
            if (sbp === "") {
                newErrors.SBP = "Systolic is required if Diastolic is present";
            }
        }

        // Heart Rate
        if (heartRate !== "") {
            const numValue = parseInt(heartRate, 10);
            if (isNaN(numValue) || numValue < 30 || numValue > 250) {
                newErrors.HR = "Heart Rate must be between 30 and 250";
            }
        }

        // Pulse Rate
        if (pulseRate !== "") {
            const numValue = parseInt(pulseRate, 10);
            if (isNaN(numValue) || numValue < 30 || numValue > 250) {
                newErrors.PR = "Pulse Rate must be between 30 and 250";
            }
        }

        // Heart Rate Variability
        if (hrv !== "") {
            const numValue = parseInt(hrv, 10);
            if (isNaN(numValue) || numValue < 1 || numValue > 200) {
                newErrors.HRV = "HRV must be between 1 and 200";
            }
        }

        // SpO2
        if (spo2 !== "") {
            const numValue = parseInt(spo2, 10);
            if (isNaN(numValue) || numValue < 60 || numValue > 100) {
                newErrors.SpO2 = "SpO2 must be between 60 and 100";
            }
        }

        // PEF
        if (pef !== "") {
            const numValue = parseFloat(pef);
            if (isNaN(numValue) || numValue < 0.5 || numValue > 15) {
                newErrors.PEF = "PEF must be between 0.5 and 15";
            }
        }

        // FEV1
        if (fev1 !== "") {
            const numValue = parseFloat(fev1);
            if (isNaN(numValue) || numValue < 0.2 || numValue > 12) {
                newErrors.FEV1 = "FEV1 must be between 0.2 and 12";
            }
        }

        // Respiratory Rate
        if (breathingRate !== "") {
            const numValue = parseInt(breathingRate, 10);
            if (isNaN(numValue) || numValue < 6 || numValue > 50) {
                newErrors.RR = "Respiratory Rate must be between 6 and 50";
            }
        }

        // Weight
        if (weight !== "") {
            const numValue = parseFloat(weight);
            if (isNaN(numValue) || numValue < 20 || numValue > 1000) {
                newErrors.Weight = "Weight must be between 20 and 1000";
            }
        }

        // Glucose Level
        if (glucoseLevel !== "") {
            const numValue = parseInt(glucoseLevel, 10);
            if (isNaN(numValue) || numValue < 20 || numValue > 500) {
                newErrors.Glucose = "Glucose Level must be between 20 and 500";
            }
        }

        setErrors(newErrors);
    };

    useEffect(() => {
        validateAllMeasurements();
    }, [temp, sbp, dbp, heartRate, pulseRate, hrv, spo2, pef, fev1, breathingRate, weight, glucoseLevel, manualDateTime, manualTimezone]);



    const handleFocus = (event) => event.target.select();

    useImperativeHandle(ref, () => {
        return {
            handleQuickDrawerOpen: handleQuickDrawerOpen,
        };
    });
    const closeLoaderIn5Seconds = () => {
        setTimeout(() => {
            setShowLoader(false);
            setDisplayStatus(true);
        }, 3000);
    };

    useEffect(() => {
        if (manualEntryStatus && displayStatus) {
            closeDrawerIn3Seconds();
        }
    }, [manualEntryStatus, displayStatus]);

    const closeDrawerIn3Seconds = () => {
        setTimeout(() => {
            dispatch(setUploadManualEntryStatus(false));
            dispatch(setShowMEDrawer(false));
            setStepNumber(1);
            resetMeasurements();
            setDisplayStatus(false);
        }, 2000);
    }


    return (
        <div className={classesQV.root} ref={refOne}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={isMEOpen}
            ></Backdrop>
            <main></main>
            <Drawer
                className={classesQV.drawer}
                anchor="right"
                variant="persistent"
                open={isMEOpen}
                classes={{
                    paper: classesQV.drawerPaper,
                }}
            >

                <div className={styles.qvHeader}>
                    <Avatar className={styles.avatar} alt="avatar_patient" src={ProfileIcon}></Avatar>
                    <div className={styles.avatarTitleDiv}>
                        <div className={styles.avatarTitle}> {selectedPCCPatient.fullName} </div>
                        <div className={styles.avatarTitle1}> {selectedPCCPatient.medicalRecordNumber} </div>
                    </div>
                </div>

                <div className={styles.drawerTitle}>
                    <TouchAppIcon></TouchAppIcon> Manual Measurement
                </div>

                {stepNumber === 1 && (<>
                    <div className={styles.qvDiv}>
                        <Grid
                            container
                            spacing={0}
                            style={{ padding: 10 }}
                        >
                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <TempIcon className={styles.highRiskIcon} size={35} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={temp}
                                            onChange={e => setTemp(e.currentTarget.value)}
                                            className={errors.Temp ? `${styles.patientCountHighRiskInput} ${styles.errorInput}` : styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>Temp (F)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <BPIcon className={styles.highRiskIcon} size={35} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={sbp}
                                            onChange={e => setSbp(e.currentTarget.value)}
                                            className={errors.SBP ? `${styles.patientCountHighRiskInput} ${styles.errorInput}` : styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)}
                                            onKeyDown={e => {
                                                if (e.key === '.' || e.key === 'e') {
                                                    e.preventDefault(); 
                                                }
                                            }} />
                                        <div className={styles.patientCatagoryHighRisk}>SBP (mmHg)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <BPIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={dbp}
                                            onChange={e => setdbp(e.currentTarget.value)}
                                            className={errors.DBP ? `${styles.patientCountHighRiskInput} ${styles.errorInput}` : styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} 
                                            onKeyDown={e => {
                                                if (e.key === '.' || e.key === 'e') {
                                                    e.preventDefault(); 
                                                }
                                            }}/>
                                        <div className={styles.patientCatagoryHighRisk}>DBP (mmHg)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <HRIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={heartRate}
                                            onChange={e => setHeartRate(e.currentTarget.value)}
                                            className={errors.HR ? `${styles.patientCountHighRiskInput} ${styles.errorInput}` : styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} 
                                            onKeyDown={e => {
                                                if (e.key === '.' || e.key === 'e') {
                                                    e.preventDefault(); 
                                                }
                                            }}/>
                                        <div className={styles.patientCatagoryHighRisk}>HR (bpm)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <PRIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={pulseRate}
                                            onChange={e => setPulseRate(e.currentTarget.value)}
                                            className={errors.PR ? `${styles.patientCountHighRiskInput} ${styles.errorInput}` : styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)}
                                            onKeyDown={e => {
                                                if (e.key === '.' || e.key === 'e') {
                                                    e.preventDefault(); 
                                                }
                                            }} />
                                        <div className={styles.patientCatagoryHighRisk}>PR (bpm)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <HRVIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={hrv}
                                            onChange={e => setHrv(e.currentTarget.value)}
                                            className={errors.HRV ? `${styles.patientCountHighRiskInput} ${styles.errorInput}` : styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} 
                                            onKeyDown={e => {
                                                if (e.key === '.' || e.key === 'e') {
                                                    e.preventDefault(); 
                                                }
                                            }}/>
                                        <div className={styles.patientCatagoryHighRisk}>HRV (ms)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <SPOIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={spo2}
                                            onChange={e => setSpo2(e.currentTarget.value)}
                                            className={errors.SpO2 ? `${styles.patientCountHighRiskInput} ${styles.errorInput}` : styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} 
                                            onKeyDown={e => {
                                                if (e.key === '.' || e.key === 'e') {
                                                    e.preventDefault(); 
                                                }
                                            }}/>
                                        <div className={styles.patientCatagoryHighRisk}>SpO2 (%)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <PEFIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={pef}
                                            onChange={e => setPef(e.currentTarget.value)}
                                            className={errors.PEF ? `${styles.patientCountHighRiskInput} ${styles.errorInput}` : styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>
                                            PEF (L/sec)
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <FEVIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={fev1}
                                            onChange={e => setFev1(e.currentTarget.value)}
                                            className={errors.FEV1 ? `${styles.patientCountHighRiskInput} ${styles.errorInput}` : styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>FEV1 (L)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <RRIcon className={styles.highRiskIcon} size={40} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={breathingRate}
                                            onChange={e => setBreathingRate(e.currentTarget.value)}
                                            className={errors.RR ? `${styles.patientCountHighRiskInput} ${styles.errorInput}` : styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)}
                                            onKeyDown={e => {
                                                if (e.key === '.' || e.key === 'e') {
                                                    e.preventDefault(); 
                                                }
                                            }} />
                                        <div className={styles.patientCatagoryHighRisk}>
                                            RR (br/min)
                                        </div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <WeightIcon className={styles.highRiskIcon} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={weight}
                                            onChange={e => setWeight(e.currentTarget.value)}
                                            className={errors.Weight ? `${styles.patientCountHighRiskInput} ${styles.errorInput}` : styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>Weight (lbs)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardHighRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <GlucoseIcon className={styles.highRiskIcon} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="number"
                                            placeholder="__"
                                            value={glucoseLevel}
                                            onChange={e => setGlucoseLevel(e.currentTarget.value)}
                                            onKeyDown={e => {
                                                if (e.key === '.' || e.key === 'e') {
                                                    e.preventDefault(); 
                                                }
                                            }}
                                            className={errors.Glucose ? `${styles.patientCountHighRiskInput} ${styles.errorInput}` : styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryHighRisk}>Glu. (mg/dL)</div>
                                    </div>
                                </div>
                            </Grid>

                            <div className={styles.painDiv}>
                                <div className={styles.painDivDesc}>
                                    Rate your pain level on a scale of 0 - 10
                                </div>
                                <div className={styles.painLevelRating}>
                                    <div className={painLevel >= 0 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(0)}>0</div>
                                    <div className={painLevel && painLevel >= 1 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(1)}>1</div>
                                    <div className={painLevel && painLevel >= 2 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(2)}>2</div>
                                    <div className={painLevel && painLevel >= 3 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(3)}>3</div>
                                    <div className={painLevel && painLevel >= 4 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(4)}>4</div>
                                    <div className={painLevel && painLevel >= 5 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(5)}>5</div>
                                    <div className={painLevel && painLevel >= 6 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(6)}>6</div>
                                    <div className={painLevel && painLevel >= 7 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(7)}>7</div>
                                    <div className={painLevel && painLevel >= 8 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(8)}>8</div>
                                    <div className={painLevel && painLevel >= 9 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(9)}>9</div>
                                    <div className={painLevel && painLevel >= 10 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(10)}>10</div>
                                </div>
                            </div>

                            <div className={styles.addNewNoteDiv}>
                                <Input
                                    className={styles.notesEditor}
                                    type="text"
                                    placeholder="Start typing..."
                                    value={newNotes}
                                    multiline={true}
                                    onChange={(event) => setNewNotes(event.target.value)}
                                    disableUnderline="true"
                                    minRows={2}
                                    maxRows={3}
                                />
                            </div>
                            <div className={styles.timeStampStyles}>{timestamp.toString()}</div>
                            {/* <div className={styles.timeStampStyles}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={useManualTime}
                                            onChange={handleUseManualTimeChange}
                                            name="useManualTime"
                                            color="primary"
                                        />
                                    }
                                    label="Use manual time"
                                />
                                {useManualTime && (
                                    <div className={styles.manualTimeContainer}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                label="Select Date & Time"
                                                value={manualDateTime}
                                                maxDate={maxDate}
                                                minDate={minDate}
                                                onChange={(newValue) => setManualDateTime(newValue)}
                                                slotProps={{
                                                    textField: {
                                                        error: errors.date,
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                        <FormControl fullWidth>
                                            <InputLabel id="timezone-label">Timezone</InputLabel>
                                            <Select
                                                labelId="timezone-label"
                                                label="Timezone"
                                                value={manualTimezone}
                                                onChange={(event) => setManualTimezone(event.target.value)}
                                            >
                                                {timezoneOptions.map((tz) => (
                                                    <MenuItem key={tz.value} value={tz.value}>
                                                        {tz.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}
                            </div> */}

                        </Grid>
                    </div>
                    <div className={styles.loaderDiv}>
                        {attemptedSubmit && Object.keys(errors).length > 0 && (
                            <div className={styles.errorText}>
                                Invalid value(s) for {Object.keys(errors).join(', ')}
                            </div>
                        )}
                        {showLoader && <CircularProgress style={{ color: `#4E253A` }} ></CircularProgress>}
                        {displayStatus && (manualEntryStatus ? (<div className={styles.loaderMessageSuccess}>Measurement submitted successfully!</div>) : (<div className={styles.loaderMessageFailure}>Measurement submission failed.</div>))}
                    </div>
                    <div className={styles.buttonsDiv}>
                        <div className={styles.retakeButton} onClick={() => handleQuickDrawerClose()}>
                            Cancel
                        </div>
                        {(displayStatus && !manualEntryStatus) ? (
                            <div className={styles.completeButton} onClick={() => retakeMeasurements()}>
                                Retake
                            </div>
                        ) : (
                            <div className={styles.completeButton} onClick={() => handleSubmitFinalMeasurements()}>
                                Submit
                            </div>
                        )}
                    </div>
                </>)}

                {stepNumber === 3 && !measurementSuccess && (<>
                    <div className={styles.mlInsDiv}>
                    </div>
                    <div className={styles.cancelButton} onClick={() => handleQuickDrawerClose()}>
                        Cancel
                    </div>
                    <div className={styles.stepTwoWarningDiv}>
                        The measurement upload failed. Please retry manually entering the measurements.
                    </div>
                </>)}
            </Drawer>
        </div >
    );
});
export default MEPannel;
