import React, {
    forwardRef,
    useImperativeHandle,
    useEffect,
    useRef,
    useState,
} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Avatar from "@material-ui/core/Avatar";
import ProfileIcon from "../../../../Assets/Images/ProfileIcon.png";
import styles from "./QMPannel.module.css";
import Step1Img from "../../../../Assets/Images/__Hand-Unit-Image-01.png";
import Step2Img from "../../../../Assets/Images/__Hand-Unit-Image-02.png";
import Step3Img from "../../../../Assets/Images/__Hand-Unit-Image-03.png";
import Step4Img from "../../../../Assets/Images/No-Conection.png";
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { getPCCUserProfileSelector, getLatestMeasurement, getPrevMeasurement } from "../../../../Reducer/selectors/NursingDashboardSelector";
import { getSelectedPCCPatientSelector, getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";
import { useSelector, useDispatch } from "react-redux";
import { getPatientVitalsList, setSelectedMlId } from "../../../../Actions";
import { getPatientVitalsListSelector } from "../../../../Reducer/selectors/PatientSelector";
import { getSelectedMlIdSelector } from "../../../../Reducer/selectors/DashboardSelector";
import PostUnassignMouthLabFromSubject from "../../../API/PostUnassignMouthLabFromSubject/PostUnassignMouthLabFromSubject";
import { setPrevMeasurement, setLatestMeasurement } from "../../../../Actions";
import Grid from "@material-ui/core/Grid";
import Input from '@material-ui/core/Input';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { updateExtraMeasurementParams } from "../../../../Actions";
import { setShowLoader } from "../../../../Actions/UtilActions";
import { getPageLoader } from "../../../../Reducer/selectors/UtilSelector";
import Modal from "@material-ui/core/Modal";
import { getShowQMDrawer } from "../../../../Reducer/selectors/NursingDashboardSelector.js";
import { setShowQMDrawer } from "../../../../Actions";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import MLImg from '../../../../../src/Assets/Images/Aidar-Hand-Device-Sketch-01.png';

import { ReactComponent as TempIcon } from "../../../../Assets/icons/01-Temp.svg";
import { ReactComponent as BPIcon } from "../../../../Assets/icons/02-BP.svg";
import { ReactComponent as RRIcon } from "../../../../Assets/icons/03-RR.svg";
import { ReactComponent as PRIcon } from "../../../../Assets/icons/04-PR.svg";
import { ReactComponent as HRIcon } from "../../../../Assets/icons/05-HR.svg";
import { ReactComponent as SPOIcon } from "../../../../Assets/icons/06-SpO2.svg";
import { ReactComponent as HRVIcon } from "../../../../Assets/icons/07-HRV.svg";
import { ReactComponent as PEFIcon } from "../../../../Assets/icons/08-PEF.svg";
import { ReactComponent as FEVIcon } from "../../../../Assets/icons/09-FEV1.svg";
import { ReactComponent as MouthlabIcon } from "../../../../Assets/icons/waveform.svg";
import { ReactComponent as AlertIcon } from "../../../../Assets/Images/Alert-Icon.svg";
import { Divider } from "@material-ui/core";

const drawerWidth = 600;
const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        backgroundColor: "#f4e7ee",
        width: drawerWidth,
    },
    typoStyle: {
        fontSize: 20,
        textTransform: `capitalize;`,
        fontFamily: `GTWalsheimProRegular`,
        width: 100,
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        justifyContent: "flex-start",
        backgroundColor: "#E4DFE9",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const QMPannel = forwardRef((props, ref) => {

    const refOne = useRef(null);
    const classesQV = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isQMOpen = useSelector(getShowQMDrawer);
    const [stepNumber, setStepNumber] = useState(1);
    const [timerProgress, setTimerProgress] = useState(1);
    const [isTimerOn, setIsTimerOn] = useState(false);
    const mlRecieveWaitTime = 1.2; // in minutes
    const selectedPCCPatient = useSelector(getSelectedPCCPatientSelector);
    const selectedPCCPatientProfile = useSelector(getPCCUserProfileSelector);
    const [response, setResponse] = useState({
        statusCode: 0,
        message: "",
    });
    const selectedMLId = useSelector(getSelectedMlIdSelector);
    const prevMeasurement = useSelector(getPrevMeasurement);
    const latestMeasurement = useSelector(getLatestMeasurement);
    const currMeas = useSelector(getPatientVitalsListSelector);
    const [newNotes, setNewNotes] = useState("");
    const [painLevel, setPainLevel] = useState();
    const [weight, setWeight] = useState("");
    const [glucoseLevel, setGlucoseLevel] = useState("");
    const [measurementSuccess, setMeasurementSuccess] = useState(false);
    const loader = useSelector(getPageLoader);
    const [showDialog, setShowDialog] = useState(false);
    const [formState, setFormState] = useState(true);
    const userProfile = useSelector(getUserProfile);
    const [firstName] = useState(userProfile.firstName);
    const [lastName] = useState(userProfile.lastName);
    const userName = firstName + " " + lastName;

    dayjs.extend(utc);
    dayjs.extend(timezone);
    const timeZoneFromDayjs = dayjs.tz.guess();

    console.log("PREV MEASUREMENT TS: ", prevMeasurement[0] ? prevMeasurement[0].timestamp : "Not found");
    console.log("LATEST MEASUREMENT TS: ", latestMeasurement[0] ? latestMeasurement[0].timestamp : "Not found");

    useEffect(() => {
        let timer = null;
        if (stepNumber === 1) {
            dispatch(setPrevMeasurement(currMeas));
        }

        if (stepNumber === 2 && timerProgress < 100) {
            timer = setTimeout(() => {
                setTimerProgress(timerProgress + 1);
            }, (mlRecieveWaitTime * 600));

            if (timerProgress === 85) {
                console.log('timerProgress: ', timerProgress);
                dispatch(getPatientVitalsList(selectedPCCPatientProfile.guid));
            }

            if (timerProgress === 95) {
                dispatch(setLatestMeasurement(currMeas));
            }
        }

        // else if (stepNumber >= 2 && timerProgress >= 100) {
        //     dispatch(getPatientVitalsList(selectedPCCPatientProfile.guid));
        //     dispatch(setLatestMeasurement(currMeas));
        // }
        // else if (isQMOpen && stepNumber < 2) {
        //     dispatch(getPatientVitalsList(selectedPCCPatientProfile.guid));
        //     dispatch(setPrevMeasurement(currMeas));
        // }
    }, [currMeas, timerProgress, stepNumber]);

    function closeDialogMenu(event, reason) {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setShowDialog(false);
            changeState();
        }
    };

    function unpairAndCloseDrawer() {
        setStepNumber(1);
        setTimerProgress(1);
        dispatch(setLatestMeasurement([]));
        // PostUnassignMouthLabFromSubject({ deviceId: selectedMLId }).then((res) => {
        //     setResponse({
        //         statusCode: res.response ? res.response.status : res.error.response.status,
        //         message: res.response ? res.response.data : res.error.response.data,
        //     });
        // });
        setPainLevel();
        setWeight("__");
        setGlucoseLevel("__");
        dispatch(setShowQMDrawer(false));
    }

    const changeState = () => {
        setFormState(!formState);
    };

    const handleQuickDrawerOpen = () => {
        dispatch(setShowQMDrawer(true));
        dispatch(setPrevMeasurement(currMeas));
    };

    const handleQuickDrawerClose = () => {
        setShowDialog(true);
    };

    const handleRetakeMaeasurement = () => {
        setStepNumber(1);
        setTimerProgress(1);
        setPainLevel();
        setWeight("");
        setGlucoseLevel("");
        setNewNotes("");
        dispatch(setLatestMeasurement([]));
        setMeasurementSuccess(false);
    }

    const handleFinalViewScreen = async () => {
        dispatch(setShowLoader(true));
        setStepNumber(3);
        dispatch(getPatientVitalsList(selectedPCCPatientProfile.guid));
        dispatch(setLatestMeasurement(currMeas));

        // console.log("PREV MEASUREMENT TS: ", prevMeasurement[0] ? prevMeasurement[0].timestamp : "Not found");
        // console.log("LATEST MEASUREMENT TS: ", latestMeasurement[0] ? latestMeasurement[0].timestamp : "Not found");

        if (latestMeasurement && latestMeasurement[0]) {
            if (prevMeasurement && prevMeasurement[0]) {
                if (prevMeasurement[0].timestamp !== latestMeasurement[0].timestamp) {
                    console.log("MEASUREMENT SUCCESSFULL!!!");
                    setMeasurementSuccess(true);
                } else {
                    console.log("MEASUREMENT UNSUCCESSFULL!!!");
                }
            } else {
                console.log("MEASUREMENT SUCCESSFULL!!!");
                setMeasurementSuccess(true);
            }
        } else {
            console.log("MEASUREMENT UNSUCCESSFULL!!!");
        }
    }

    const handleSubmitFinalMeasurements = () => {
        setStepNumber(1);
        setTimerProgress(1);
        dispatch(setLatestMeasurement([]));
        // PostUnassignMouthLabFromSubject({ deviceId: selectedMLId }).then((res) => {
        //     setResponse({
        //         statusCode: res.response ? res.response.status : res.error.response.status,
        //         message: res.response ? res.response.data : res.error.response.data,
        //     });
        // });

        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

        const newpayload = {
            recordId: latestMeasurement[0].recordId,
            subjectId: selectedPCCPatientProfile.subjectId,
            temperature: temp === "" ? undefined : temp,
            spo2: spo2 === "" ? undefined : spo2,
            breathingRate: breathingRate === "" ? undefined : breathingRate,
            pulseRate: pulseRate === "" ? undefined : pulseRate,
            heartRate: heartRate === "" ? undefined : heartRate,
            hrv: hrv === "" ? undefined : hrv,
            sbp: sbp === "" ? undefined : sbp,
            dbp: dbp === "" ? undefined : dbp,
            fev1: fev1 === "" ? undefined : fev1,
            pef: pef === "" ? undefined : pef,
            weight: isNaN(weight) ? "" : weight,
            glucose: isNaN(glucoseLevel) ? "" : glucoseLevel,
            painLevel: isNaN(painLevel) ? "" : painLevel,
            tsRecorded: dayjs(timestamp).toISOString(),
            note: newNotes,
            timezone: timeZone,
            createdBy: userProfile?.id,
        }

        console.log("EXTRA MEASUREMENTS NEW PAYLOAD: ", newpayload);

        dispatch(updateExtraMeasurementParams(newpayload));
        handleQuickDrawerClose();
        setPainLevel();
        setWeight("");
        setGlucoseLevel("");
        setNewNotes("");
        dispatch(setShowQMDrawer(false));
    }

    let timestamp = "";
    let temp = "";
    let tempRisk = '0';
    let spo2 = "";
    let spo2Risk = '0';
    let breathingRate = "";
    let breathingRateRisk = '0';
    let pulseRate = "";
    let pulseRateRisk = '0';
    let heartRate = "";
    let heartRateRisk = '0';
    let hrv = "";
    let hrvRisk = '0';
    let sbp = "";
    let sbpRisk = '0';
    let dbp = "";
    let dbpRisk = '0';
    let fev1 = "";
    let fev1Risk = '0';
    let pef = "";
    let pefRisk = '0';
    let recordId = "";

    latestMeasurement.map((allVitals) => {
        timestamp = allVitals.timestamp;
        temp = allVitals.temperature.value;
        tempRisk = allVitals.temperature.risk;
        spo2 = allVitals.spo2.value;
        spo2Risk = allVitals.spo2.risk;
        breathingRate = allVitals.breathingRate.value;
        breathingRateRisk = allVitals.breathingRate.risk;
        pulseRate = allVitals.pulseRate.value;
        pulseRateRisk = allVitals.pulseRate.risk;
        heartRate = allVitals.heartRate.value;
        heartRateRisk = allVitals.heartRate.risk;
        hrv = allVitals.hrv.value;
        hrvRisk = allVitals.hrv.risk;
        sbp = allVitals.sbp.value;
        sbpRisk = allVitals.sbp.risk;
        dbp = allVitals.dbp.value;
        dbpRisk = allVitals.sbp.Risk;
        fev1 = allVitals.fev1.value;
        fev1Risk = allVitals.fev1.risk;
        pef = allVitals.pef.value;
        pefRisk = allVitals.pef.risk;
        recordId = allVitals.recordId;
    });

    useImperativeHandle(ref, () => {
        return {
            handleQuickDrawerOpen: handleQuickDrawerOpen,
        };
    });

    const handleFocus = (event) => event.target.select();

    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress sx={{ color: "#4E253A !important" }} size="4.5rem" variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography sx={{ fontSize: "18px !important", fontWeight: "bold !important", fontFamily: "GTWalsheimProRegular" }} variant="caption" component="div" color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }

    CircularProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate variant.
         * Value between 0 and 100.
         * @default 1
         */
        value: PropTypes.number.isRequired,
    };

    window.onpopstate = (event) => {
        if (isQMOpen) {
            unpairAndCloseDrawer();
        }
    };

    const handleSubmit = () => {
        PostUnassignMouthLabFromSubject({ deviceId: selectedMLId }).then((res) => {
        });
        closeDialogMenu();
        unpairAndCloseDrawer();
    };

    const handleStepNumber1 = (event) => {
        event.preventDefault();
        setMeasurementSuccess(false);
        setStepNumber(2);
    };

    return (
        <div className={classesQV.root} ref={refOne}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={isQMOpen || showDialog}
            ></Backdrop>
            <main></main>
            <Drawer
                className={classesQV.drawer}
                anchor="right"
                variant="persistent"
                open={isQMOpen}
                classes={{
                    paper: classesQV.drawerPaper,
                }}
            >

                <div className={styles.qvHeader}>
                    <Avatar className={styles.avatar} alt="avatar_patient" src={ProfileIcon}></Avatar>
                    <div className={styles.avatarTitleDiv}>
                        <div className={styles.avatarTitle}> {selectedPCCPatient.fullName} </div>
                        <div className={styles.avatarTitle1}> {selectedPCCPatient.medicalRecordNumber} </div>
                    </div>
                </div>

                <div className={styles.drawerTitle}>
                    <MouthlabIcon style={{ height: '25px', width: '25px', color: "#4E253A" }}></MouthlabIcon> MouthLab Measurement
                </div>

                {stepNumber !== 3 && <div className={styles.mlWarningDiv}>
                    <div className={styles.warningIcon}>
                        <WarningAmberIcon sx={{ height: '20px !important', width: '20px !important', color: "#FFFFFF" }}></WarningAmberIcon>
                    </div>
                    <div className={styles.warningText}>
                        Ensure the hand-unit and mouthpiece is cleaned before use
                    </div>
                </div>}

                {stepNumber === 3 && !measurementSuccess && <div className={styles.mlWarningDiv1}>
                    <div className={styles.warningIcon}>
                        <WarningAmberIcon sx={{ height: '20px !important', width: '20px !important', color: "#FFFFFF" }}></WarningAmberIcon>
                    </div>
                    <div className={styles.warningText}>
                        Data Transmission has failed. Please enter values manually.
                    </div>
                </div>}

                {stepNumber === 1 && (loader ? (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) : (<><div className={styles.mlInsDiv}>
                    <div className={styles.setpOneDiv}>
                        <img src={Step1Img} width="92vw" height="140vh" />
                        <div className={styles.step1Ins}>
                            Instruct patient to hold the device in their left hand.
                        </div>
                    </div>
                    <Divider style={{ width: "100%", backgroundColor: "#F1BBD5" }}></Divider>
                    <div className={styles.setpTwoDiv}>
                        <img src={Step2Img} width="92vw" height="140vh" />
                        <div className={styles.step2Ins}>
                            Instruct patient to breathe normally through their mouth until they feel the device vibrate.
                        </div>
                    </div>
                    <Divider style={{ width: "100%", backgroundColor: "#F1BBD5" }}></Divider>
                    <div className={styles.setpThreeDiv}>
                        <img src={Step3Img} width="92vw" height="140vh" />
                        <div className={styles.step3Ins}>
                            Please select <b className={styles.boldText}>Next</b> only after you see the <b className={styles.boldText}>blue light</b> on the status bar turn <b className={styles.boldText}>OFF.</b>
                        </div>
                    </div>
                </div>

                    <div className={styles.buttonsDiv}>
                        <div className={styles.retakeButton} onClick={() => handleQuickDrawerClose()}>
                            Cancel
                        </div>
                        <div className={styles.completeButton} onClick={(e) => handleStepNumber1(e)}>
                            Next
                        </div>
                    </div>
                </>))}

                {stepNumber === 2 && (<>
                    <div className={styles.mlInsDiv}>
                        <img src={Step3Img} width="206vw" height="340vh" />
                    </div>
                    <div className={styles.progressDiv}>
                        <CircularProgressWithLabel value={timerProgress} />
                    </div>
                    <div className={styles.stepTwoWarningDiv}>
                        <div>Please stay on this page till the data is transmitted.</div>
                        <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>If the blue light still remains after 100%, please perform the troubleshooting steps and click 'Reset'.</div>
                    </div>
                    {timerProgress >= 100 ? (
                        <div className={styles.buttonsDiv}>
                            <div className={styles.retakeButton} onClick={() => setTimerProgress(1)}>
                                Reset
                            </div>
                            <div className={styles.completeButton} onClick={() => handleFinalViewScreen()}>
                                Next
                            </div>
                        </div>
                    ) : (<></>)}
                </>)}

                {stepNumber === 3 && measurementSuccess && (loader ? (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) : (<>
                    <div className={styles.qvDiv}>
                        <Grid
                            container
                            spacing={0}
                            // justify="center"
                            style={{ padding: 10, height: '100%' }}
                        >
                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                {tempRisk === '1' ?
                                    <div className={styles.cardHighRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <TempIcon className={styles.highRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountHighRisk}>
                                                {temp}
                                            </div>
                                            <div className={styles.patientCatagoryHighRisk}>Temp (F)</div>
                                        </div>
                                    </div>
                                    : <div className={styles.cardNoRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <TempIcon className={styles.noRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountNormal}>
                                                {temp}
                                            </div>
                                            <div className={styles.patientCatagoryNoRisk}>Temp (F)</div>
                                        </div>
                                    </div>}
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                {sbpRisk === '1' ?
                                    <div className={styles.cardHighRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <BPIcon className={styles.highRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountHighRisk}>
                                                {sbp}
                                            </div>
                                            <div className={styles.patientCatagoryHighRisk}>SBP (mmHg)</div>
                                        </div>
                                    </div>
                                    : <div className={styles.cardNoRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <BPIcon className={styles.noRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountNormal}>
                                                {sbp}
                                            </div>
                                            <div className={styles.patientCatagoryNoRisk}>SBP (mmHg)</div>
                                        </div>
                                    </div>}
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                {dbpRisk === '1' ?
                                    <div className={styles.cardHighRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <BPIcon className={styles.highRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountHighRisk}>
                                                {dbp}
                                            </div>
                                            <div className={styles.patientCatagoryHighRisk}>DBP (mmHg)</div>
                                        </div>
                                    </div>
                                    : <div className={styles.cardNoRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <BPIcon className={styles.noRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountNormal}>
                                                {dbp}
                                            </div>
                                            <div className={styles.patientCatagoryNoRisk}>DBP (mmHg)</div>
                                        </div>
                                    </div>}
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                {heartRateRisk === '1' ?
                                    <div className={styles.cardHighRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <HRIcon className={styles.highRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountHighRisk}>
                                                {heartRate}
                                            </div>
                                            <div className={styles.patientCatagoryHighRisk}>HR (bpm)</div>
                                        </div>
                                    </div>
                                    : <div className={styles.cardNoRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <HRIcon className={styles.noRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountNormal}>
                                                {heartRate}
                                            </div>
                                            <div className={styles.patientCatagoryNoRisk}>HR (bpm)</div>
                                        </div>
                                    </div>}
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                {pulseRateRisk === '1' ?
                                    <div className={styles.cardHighRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <PRIcon className={styles.highRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountHighRisk}>
                                                {pulseRate}
                                            </div>
                                            <div className={styles.patientCatagoryHighRisk}>PR (bpm)</div>
                                        </div>
                                    </div>
                                    : <div className={styles.cardNoRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <PRIcon className={styles.noRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountNormal}>
                                                {pulseRate}
                                            </div>
                                            <div className={styles.patientCatagoryNoRisk}>PR (bpm)</div>
                                        </div>
                                    </div>}
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                {hrvRisk === '1' ?
                                    <div className={styles.cardHighRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <HRVIcon className={styles.highRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountHighRisk}>
                                                {hrv}
                                            </div>
                                            <div className={styles.patientCatagoryHighRisk}>HRV (ms)</div>
                                        </div>
                                    </div>
                                    : <div className={styles.cardNoRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <HRVIcon className={styles.noRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountNormal}>
                                                {hrv}
                                            </div>
                                            <div className={styles.patientCatagoryNoRisk}>HRV (ms)</div>
                                        </div>
                                    </div>}
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                {spo2Risk === '1' ?
                                    <div className={styles.cardHighRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <SPOIcon className={styles.highRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountHighRisk}>
                                                {spo2}
                                            </div>
                                            <div className={styles.patientCatagoryHighRisk}>SpO2 (%)</div>
                                        </div>
                                    </div>
                                    : <div className={styles.cardNoRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <SPOIcon className={styles.noRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountNormal}>
                                                {spo2}
                                            </div>
                                            <div className={styles.patientCatagoryNoRisk}>SpO2 (%)</div>
                                        </div>
                                    </div>}
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                {pefRisk === '1' ?
                                    <div className={styles.cardHighRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <PEFIcon className={styles.highRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountHighRisk}>
                                                {pef}
                                            </div>
                                            <div className={styles.patientCatagoryHighRisk}>PEF (L/sec)</div>
                                        </div>
                                    </div>
                                    : <div className={styles.cardNoRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <PEFIcon className={styles.noRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountNormal}>
                                                {pef}
                                            </div>
                                            <div className={styles.patientCatagoryNoRisk}>PEF (L/sec)</div>
                                        </div>
                                    </div>}
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                {fev1Risk === '1' ?
                                    <div className={styles.cardHighRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <FEVIcon className={styles.highRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountHighRisk}>
                                                {fev1}
                                            </div>
                                            <div className={styles.patientCatagoryHighRisk}>FEV1 (L)</div>
                                        </div>
                                    </div>
                                    : <div className={styles.cardNoRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <FEVIcon className={styles.noRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountNormal}>
                                                {fev1}
                                            </div>
                                            <div className={styles.patientCatagoryNoRisk}>FEV1 (L)</div>
                                        </div>
                                    </div>}
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                {breathingRateRisk === '1' ?
                                    <div className={styles.cardHighRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <RRIcon className={styles.highRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountHighRisk}>
                                                {breathingRate}
                                            </div>
                                            <div className={styles.patientCatagoryHighRisk}>RR (br/min)</div>
                                        </div>
                                    </div>
                                    : <div className={styles.cardNoRisk}>
                                        <div className={styles.iconDivStyles}>
                                            <RRIcon className={styles.noRiskIcon} size={35} />
                                        </div>
                                        <div className={styles.countDivStyles}>
                                            <div className={styles.patientCountNormal}>
                                                {breathingRate}
                                            </div>
                                            <div className={styles.patientCatagoryNoRisk}>RR (br/min)</div>
                                        </div>
                                    </div>}
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardNoRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <EditOutlinedIcon className={styles.noRiskIcon} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="text"
                                            placeholder="__"
                                            value={weight}
                                            onChange={e => setWeight(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryNoRisk}>Weight (lbs)</div>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                                <div className={styles.cardNoRisk}>
                                    <div className={styles.iconDivStyles}>
                                        <EditOutlinedIcon className={styles.noRiskIcon} />
                                    </div>
                                    <div className={styles.countDivStyles}>
                                        <input
                                            type="text"
                                            placeholder="__"
                                            value={glucoseLevel}
                                            onChange={e => setGlucoseLevel(e.currentTarget.value)}
                                            className={styles.patientCountHighRiskInput}
                                            onFocus={e => handleFocus(e)} />
                                        <div className={styles.patientCatagoryNoRisk}>Glu. (mg/dL)</div>
                                    </div>
                                </div>
                            </Grid>

                            <div className={styles.painDiv}>
                                <div className={styles.painDivDesc}>
                                    Rate your pain level on a scale of 0 - 10
                                </div>
                                <div className={styles.painLevelRating}>
                                    <div className={painLevel >= 0 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(0)}>0</div>
                                    <div className={painLevel && painLevel >= 1 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(1)}>1</div>
                                    <div className={painLevel && painLevel >= 2 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(2)}>2</div>
                                    <div className={painLevel && painLevel >= 3 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(3)}>3</div>
                                    <div className={painLevel && painLevel >= 4 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(4)}>4</div>
                                    <div className={painLevel && painLevel >= 5 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(5)}>5</div>
                                    <div className={painLevel && painLevel >= 6 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(6)}>6</div>
                                    <div className={painLevel && painLevel >= 7 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(7)}>7</div>
                                    <div className={painLevel && painLevel >= 8 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(8)}>8</div>
                                    <div className={painLevel && painLevel >= 9 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(9)}>9</div>
                                    <div className={painLevel && painLevel >= 10 ? styles.selectedRatingdiv : styles.ratingdiv} onClick={() => setPainLevel(10)}>10</div>
                                </div>
                            </div>

                            <div className={styles.addNewNoteDiv}>
                                <Input
                                    className={styles.notesEditor}
                                    type="text"
                                    placeholder="Start typing..."
                                    value={newNotes}
                                    multiline={true}
                                    onChange={(event) => setNewNotes(event.target.value)}
                                    disableUnderline="true"
                                />
                            </div>
                            <div className={styles.timeStampStyles}>{dayjs.utc(timestamp).tz(timeZoneFromDayjs).format('YYYY-MM-DD HH:mm')}</div>
                        </Grid>
                    </div>
                    <div className={styles.buttonsDiv}>
                        <div className={styles.retakeButton} onClick={() => handleRetakeMaeasurement()}>
                            Retake
                        </div>
                        <div className={styles.completeButton} onClick={() => handleSubmitFinalMeasurements()}>
                            Done
                        </div>
                    </div>
                </>))}

                {stepNumber === 3 && !measurementSuccess && (loader ? (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) : (<>
                    <div className={styles.mlInsDiv}>
                        <img src={Step4Img} width="234vw" height="510vh" />
                    </div>
                    {/* <div className={styles.cancelButton} onClick={() => handleQuickDrawerClose()}>
                        Done
                    </div> */}
                    <div className={styles.buttonsDiv}>
                        <div className={styles.retakeButton} onClick={() => handleRetakeMaeasurement()}>
                            Retake
                        </div>
                        <div className={styles.completeButton} onClick={() => handleQuickDrawerClose()}>
                            Done
                        </div>
                    </div>
                </>))}
            </Drawer>

            {/* MouthLab removal from subject */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={showDialog}
                onClose={(event, reason) => closeDialogMenu(event, reason)}
                hideBackdrop={true}
            >
                <div className={styles.paperUnassignML}>
                    <div className={styles.menuTitle}>Unassign MouthLab</div>

                    <div className={styles.userQuestionMessage}> Unassign ML {selectedMLId} from subject {selectedPCCPatient.fullName} ? </div>

                    <div className={styles.mouthLabImgDiv}>
                        <img src={MLImg} loading="lazy" className={styles.mlImg} />
                    </div>

                    <div className={styles.buttonContainer}>
                        <button type="button" onClick={closeDialogMenu} className={styles.cancelButton1}>
                            Cancel
                        </button>
                        <button type="submit" onClick={handleSubmit} className={styles.submitButton}>
                            Confirm
                        </button>
                    </div>
                </div>
            </Modal>
        </div >
    );
});
export default QMPannel;